import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import ReactLoading from "react-loading";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import default_img from "../../assets/img/emmy.png";

// const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;

const Profile = () => {
  const history = useHistory();
  const [errorlist, setError] = useState([]);
  const [registerInput, setUser] = useState([]);
  const [allcheckbox, setCheckboxes] = useState([]);
  const [picture, setPicture] = useState({});
  const [loading, setLoading] = useState(true);

  let ifloaded = false;
  const registerSubmit = (e) => {
    e.preventDefault();

    console.log("sumbit is triggered");
    const formData = new FormData();

    formData.append("image", picture.image);
    formData.append("firstname", registerInput.firstname);
    formData.append("lastname", registerInput.lastname);
    formData.append("email", registerInput.email);

    formData.append("zipcode", registerInput.zipcode);
    formData.append("address1", registerInput.address1);
    formData.append("address2", registerInput.address2);

    formData.append("password1", registerInput.password1);
    formData.append("password2", registerInput.password2);
    formData.append("bio", registerInput.bio);

    formData.append("status", allcheckbox.status ? "1" : "0");

    axios.post(`/api/updateUserDetail`, formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setError([]);
        history.push("/v-admin/settings");
        ifloaded = true;
      } else if (res.data.status === 422) {
        swal("All fields are Mandatory", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/v-admin/settings");
      }
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("auth_token")) {
      history.push("/v-admin");
      swal("Warning", "Restricted!", "error");
    }

    document.title = "Profile";
    let isMounted = true;

    axios.get(`/api/getUserDetail`).then((res) => {
      if (res.data.status === 200) {
        setUser(res.data.user);
        setCheckboxes(res.data.user);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/v-admin");
      }
      setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [history]);

  const handleInput = (e) => {
    e.persist();
    setUser({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (e) => {
    e.persist();
    setCheckboxes({ ...allcheckbox, [e.target.name]: e.target.checked });
  };

  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } return (
    <>
      <div class="container font-1 mb-4 mt-4" style={{ width: "90%" }}>
        <div class="row justify-content-center">
          <div class="col-md-12 ">
            <form class="mt-3 row" onSubmit={registerSubmit}>
              <div class="row">
                {/* src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${productInput.image}`} */}
                <div class="col-lg-12 mt-5 mt-lg-0">
                  <div className="row mb-4 mt-0 form-group">
                    <div className="col-lg-2 ">
                      <div class="col ">
                        <a
                          href="assets/images/portraits/square/03.jpg"
                          data-lightbox="profile-pic"
                        >
                          <LazyLoadImage
                            alt="error_thumbnail"
                            className="img-thumbnail radius-primary"
                            effect="blur"
                            width="100%"
                            // src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${registerInput.image}`}
                            src={
                              registerInput.image === ""
                                ? `${default_img}`
                                : `${process.env.REACT_APP_LOCALHOST_APP_URL}/${registerInput.image}`
                            }
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-6 text-lg-left">
                      <h3 class="fs-5 color-black mb-2  mt-2  ">
                        <strong>Admin Information</strong>
                      </h3>
                      <h6 class="text-muted font-weight-normal">
                        {registerInput.firstname} {registerInput.lastname}
                      </h6>
                      <p class="text-muted text-small">{registerInput.email}</p>
                    </div>

                    <div className="col-lg-4">
                      <label className="text-danger">
                        Change Profile Picture
                      </label>
                      <div className="col-12">
                        <input
                          type="file"
                          name="image"
                          onChange={handleImage}
                          className="form-control "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <label for="exampleInputSize3" className="text-left">
                  First Name
                </label>
                <input
                  name="firstname"
                  class="form-control mb-3 input-lg"
                  type="text"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.firstname}
                />{" "}
                <label className="span_label">{errorlist.firstname}</label>
              </div>
              <div class="col-md-6">
                <label for="exampleInputSize3" className="text-left">
                  Last Name
                </label>
                <input
                  name="lastname"
                  class="form-control mb-3  input-lg"
                  type="text"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.lastname}
                />{" "}
                <label className="span_label">{errorlist.lastname}</label>
              </div>
              <div class="col-12">
                <label for="exampleInputSize3" className="text-left">
                  Email
                </label>
                <input
                  class="form-control mb-3 input-lg"
                  name="email"
                  type="email"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.email}
                />{" "}
                <label className="span_label">{errorlist.email}</label>
              </div>
              <div class="col-12">
                <label for="exampleInputSize3" className="text-left">
                  Address 1
                </label>
                <input
                  class="form-control mb-3 input-lg"
                  name="address1"
                  type="text"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.address1}
                />{" "}
                <label className="span_label">{errorlist.address1}</label>
              </div>
              <div class="col-12">
                <label for="exampleInputSize3" className="text-left">
                  Address 2 (Optional)
                </label>
                <input
                  class="form-control mb-3 input-lg"
                  name="address2"
                  type="text"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.address2}
                />{" "}
                <label className="span_label">{errorlist.address2}</label>
              </div>

              <div class="col-12">
                <label for="exampleInputSize3" className="text-left">
                  Zip Code
                </label>
                <input
                  class="form-control mb-3 input-lg"
                  name="zipcode"
                  type="text"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.zipcode}
                />{" "}
                <label className="span_label">{errorlist.zipcode}</label>
              </div>

              <div class="col-md-6">
                <label for="exampleInputSize3" className="text-left">
                  Password
                </label>
                <input
                  class="form-control mb-3 input-lg"
                  type="password"
                  name="password1"
                  placeholder=""
                />
                {""}
                <label className="span_label mb-2">{errorlist.password1}</label>
              </div>
              <div class="col-md-6">
                <label for="exampleInputSize3" className="text-left">
                  Re-type Password
                </label>
                <input
                  class="form-control mb-3 input-lg"
                  type="password"
                  name="password2"
                  placeholder=""
                />
                {""}
                <label className="span_label mb-2">{errorlist.password2}</label>
              </div>
              <div class="col-12">
                <label for="exampleInputSize3" className="text-left">
                  Tell Us About Your Taste (Optional)
                </label>
                <textarea
                  class="form-control mb-3"
                  name="bio"
                  rows="8"
                  placeholder=""
                  onChange={handleInput}
                  value={registerInput.bio}
                ></textarea>
                <label className="span_label mb-2">{errorlist.bio}</label>
              </div>

              <div className="col-md-4">
                <button class="btn btn-outline-warning btn-lg btn-fw px-4 mt-2 " type="submit">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
