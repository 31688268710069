import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
// import img from "../../../assets_old/admin/assets/img/logo1.png";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";

import default_img from "../../assets/img/emmy.png";
import vlogo  from '../../assets/img/logo.png'


export default function TransactionDetails(props) {
  const history = useHistory();

  const [allOrderItems, setAllOrderItems] = useState([]);
  const [OrderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userorders = props.match.params.user_id;
    console.log(userorders);
    axios.get(`/api/get-tran-details/${userorders}`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.order_data);
        setAllOrderItems(res.data.order_data);
        setOrderItems(res.data.order_data.userOrderItem);
        setLoading(false);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/admin/dashboard");
      }
    });
  }, []);

  var display_TransactionRecipts = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        {/* <img src={default_img} width="120px" /> */}
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } else {
    display_TransactionRecipts = <>
          <div class="preview-item border-bottom" > 
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-warning">
                      <i class="mdi mdi-home"></i>
                    </div>
                  </div>
                  <div class="preview-item-content d-sm-flex flex-grow">
                    <div class="flex-grow">
                      <h6 class="preview-subject">
                      {allOrderItems.userOrder.property_name}
                      </h6>
                      <p class="text-muted mb-0">
                      No of Expected Guest: {allOrderItems.userOrder.noofguest}
                      </p>
                    </div>
                    <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                    <span className="menu-icon">
                              <i className="mdi mdi-currency-ngn"> </i>
                            </span>
                            <h3>{allOrderItems.userOrder.price}</h3>
                    </div>
                  </div>
                </div>
  
        </>
    
  }

  return (
    <div>
      <div className="container" style={{ "color":"white"}}>
        <section className="background-11 pt-0 pt-md-6 pt-lg-8">
          <div className="container">
            <div className="row">
              <div className=" font-1">
                <div className="mb-4 mt-4">
                  <span className="fs--1 color-1 mr-3 ">
                    {" "}
                    <img src={vlogo} width="100px" className="" /> V-HOSPITALITY
                    {/* <a
                href="javascript:window.print()"
                className="btn  btn-sm btn-dark float-end"
              >
                Print Receipt
              </a> */}
                  </span>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <h3 className=" mt-3 text-muted">
                        {" "}
                        Invoice for {allOrderItems.userOrder.firstname}{" "}
                        {allOrderItems.userOrder.lastname}
                      </h3>
                      <div class="preview-item border-bottom">
                        
                        <div class="preview-item-content d-flex flex-grow">
                          <div class="flex-grow">
                            <div class="d-flex d-md-block d-xl-flex justify-content-between">
                              <h6 class="text-muted">Payment ID: {allOrderItems.userOrder.payment_id}</h6>
                            </div>
                              <p class="text-muted"><strong>Email</strong>: {allOrderItems.userOrder.email}</p>
                            <p class="text-muted"><strong>Customer's Contact</strong>: {allOrderItems.userOrder.phone}</p>
                          </div>
                        </div>
                      </div>

      <div className="row">
      <div class="col-md-12 grid-margin stretch-card">
      

          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row justify-content-between">
              {/* <img src={vlogo} width="100px" className="" /> V-HOSPITALITY */}
                <h4 class="card-title mb-1">Quick Overview</h4>
                <p class="text-muted mb-1">Value</p>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="preview-list">
                    
                  {display_TransactionRecipts}
                 

                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
                <a href="javascript:window.print()">
                <button class="btn btn-outline-warning btn-lg btn-fw px-4 mt-2 " type="submit">
                Print Receipt
                </button>
                </a>
              </div>
              
    </div>
  );
}
