import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { MDBInputGroup, MDBBtn } from "mdb-react-ui-kit";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function AddHouseProduct() {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [categorylist, setCategorylist] = useState([]);
  const history = useHistory();

  useEffect(() => {
    document.title = "add house product";
    axios.get(`/api/all-category`).then((res) => {
      console.log(res.data.category);
      if (res.data.status === 200) {
        setCategorylist(res.data.category);
      }
    });
  }, []);

  const [productInput, setProductInput] = useState({
    category_id: "",
    slug: "",
    name: "",
    description: "",

    meta_title: "",
    meta_keyword: "",
    meta_descrip: "",

    selling_price: "",
    original_price: "",
    qty: "",

    beds: "",
    baths: "",
    telephone: "",
    vendor_email: "",
    
    checkin: "",
    checkout: "",
    long: "",
    lat: "",

    swimmingpool: "",
    freeparking: "",
    roomservice: "",
    restuarant: "",
    nosmoke: "",
    frdesk: "",
    laundry: "",

    brand: "",
    location: "",
    featured: "",
    popular: "",
    status: "",
  });

  const [picture, setPicture] = useState({});
  const [picture2, setPicture2] = useState({});

  const [errorlist, setError] = useState([]);

  const handleInput = (e) => {
    e.persist();
    setProductInput({ ...productInput, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    e.persist();
    setPicture({ imageFile: e.target.files[0] });
  };
  const handleImage2 = (e) => {
    e.persist();
    setPicture2({ imageFile2: e.target.files[0] });
  };

  const submitProduct = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imageFile", picture.imageFile);
    formData.append("imageFile2", picture2.imageFile2);

    formData.append("category_id", productInput.category_id);
    formData.append("slug", productInput.slug);
    formData.append("name", productInput.name);
    formData.append("description", productInput.description);

    formData.append("meta_title", productInput.meta_title);
    formData.append("meta_keyword", productInput.meta_keyword);
    formData.append("meta_descrip", productInput.meta_descrip);

    formData.append("selling_price", productInput.selling_price);
    formData.append("original_price", productInput.original_price);
    formData.append("qty", productInput.qty);

    formData.append("beds", productInput.beds);
    formData.append("baths", productInput.baths);
    formData.append("telephone", productInput.telephone);
    formData.append("vendor_email", productInput.vendor_email);

    formData.append("checkin", productInput.checkin);
    formData.append("checkout", productInput.checkout);

    formData.append("long", productInput.long);
    formData.append("lat", productInput.lat);

    formData.append("swimmingpool", productInput.swimmingpool ? "1" : "0");
    formData.append("freeparking", productInput.freeparking ? "1" : "0");
    formData.append("roomservice", productInput.roomservice ? "1" : "0");
    formData.append("restuarant", productInput.restuarant ? "1" : "0");
    formData.append("nosmoke", productInput.nosmoke ? "1" : "0");
    formData.append("frdesk", productInput.frdesk ? "1" : "0");
    formData.append("laundry", productInput.laundry ? "1" : "0");

    formData.append("brand", productInput.brand);
    formData.append("location", productInput.location);
    formData.append("featured", productInput.featured ? "1" : "0");
    formData.append("popular", productInput.popular ? "1" : "0");
    formData.append("status", productInput.status ? "1" : "0");

    axios.post("/api/store-house", formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setProductInput({
          ...productInput,
          category_id: "",
          slug: "",
          name: "",
          description: "",
          meta_title: "",
          meta_keyword: "",
          meta_descrip: "",
          selling_price: "",
          original_price: "",

          beds: "",
          baths: "",
          telephone: "",
          vendor_email: "",

          swimmingpool: "",
          freeparking: "",
          roomservice: "",
          restuarant: "",
          nosmoke: "",
          frdesk: "",
          laundry: "",

          checkin: "",
          checkout: "",
          long: "",
          lat: "",

          qty: "",
          brand: "",
          featured: "",
          popular: "",
          status: "",
        });
        setError([]);
        history.push("/v-admin/view-houseproduct");
      } else if (res.data.status === 422) {
        swal("All Fields are Mandatory", "", "error");
        setError(res.data.errors);
      }
    });
  };

  return (
    <>
      <div class="row ">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Add New Property </h4>

              <nav aria-label="breadcrumb" className="align-self-start">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="/v-admin/view-houseproduct" href="#">
                      View Category
                    </Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Add Category
                  </li>
                </ol>
              </nav>

              <form className="mt-0" onSubmit={submitProduct}>
                <div className="form-group mb-3">
                  <label>Select Category</label>
                  <select
                    name="category_id"
                    onChange={handleInput}
                    value={productInput.category_id}
                    className="form-control input-lg"
                  >
                    <option>Select Category</option>
                    {categorylist.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <small className="text-warning">{errorlist.category_id}</small>
                </div>
                {/* <div className="form-group mb-3">
                    <label>Slug</label>
                    <input
                      type="text"
                      name="slug"
                      onChange={handleInput}
                      value={productInput.slug}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">{errorlist.slug}</small>
                  </div> */}
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInput}
                    value={productInput.name}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{errorlist.name}</small>
                </div>
                <div className="form-group mb-3">
                  <label>
                    Property Description{" "}
                    <span className="text-muted">
                    {" "}(Brief description about this property category | max:1,000 words)
                    </span>
                  </label>
                  <textarea
                    name="description"
                    onChange={handleInput}
                    value={productInput.description}
                    className="form-control input-lg"
                    rows="6"
                  ></textarea>
                  <small className="text-warning">{errorlist.description}</small>
                </div>

                {/* SEO TAGS */}
                {/* <div className="form-group mb-3">
                    <label>Meta Title</label>
                    <input
                      type="text"
                      name="meta_title"
                      onChange={handleInput}
                      value={productInput.meta_title}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.meta_title}
                    </small>
                  </div>
                  <div className="form-group mb-3">
                    <label>Meta Keyword</label>
                    <textarea
                      name="meta_keyword"
                      onChange={handleInput}
                      value={productInput.meta_keyword}
                      className="form-control input-lg"
                    ></textarea>
                  </div>
                  <div className="form-group mb-3">
                    <label>Meta Description</label>
                    <textarea
                      name="meta_descrip"
                      onChange={handleInput}
                      value={productInput.meta_descrip}
                      className="form-control input-lg"
                    ></textarea>
                  </div> */}

                {/* Other Details */}
                <div className="row mb-5">
                  <div className="col-md-4 form-group mb-3">
                    <label>Selling Price</label>
                    <input
                      type="number"
                      name="selling_price"
                      onChange={handleInput}
                      value={productInput.selling_price}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.selling_price}
                    </small>
                  </div>
                  <div className="col-md-4 form-group mb-3">
                    <label>Original Price</label>
                    <input
                      type="number"
                      name="original_price"
                      onChange={handleInput}
                      value={productInput.original_price}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.original_price}
                    </small>
                  </div>
                  <div className="col-md-4 form-group mb-3">
                    <label>Maximum No of Guests</label>
                    <input
                      type="number"
                      name="qty"
                      onChange={handleInput}
                      value={productInput.qty}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">{errorlist.qty}</small>
                  </div>
                  <div className="col-md-4 form-group mb-3">
                    <label>Brand</label>
                    <select
                      name="brand"
                      onChange={handleInput}
                      value={productInput.brand}
                      className="form-control input-lg"
                    >
                      <option>Select Brand</option>
                      {categorylist.map((item) => {
                        return (
                          <option value={item.name} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <small className="text-warning">{errorlist.brand}</small>
                  </div>

                  <div className="col-md-4 form-group mb-3">
                    <label>Location</label>
                    <select
                      name="location"
                      onChange={handleInput}
                      value={productInput.location}
                      className="form-control input-lg">
                      <option>Select Location</option>
                      <option value="Asokoro" >Asokoro</option>
                      <option value="Gudu" >Gudu</option>
                      <option value="Gwarinpa" >Gwarinpa</option>
                      <option value="Idu" >Idu</option>
                      <option value="Jabi" >Jabi</option>
                      <option value="Jahi" >Jahi</option>
                      <option value="Lugbe" >Lugbe</option>
                      <option value="Life-camp" >Life-camp</option>
                      <option value="Katampe" >Katampe</option>
                      <option value="Maitama" >Maitama</option>
                    </select>

                    <small className="text-warning">{errorlist.location}</small>
                  </div>

                  <div className="col-md-4 form-group mb-3">
                    <label>Telephone</label>
                    <input
                      type="text"
                      name="telephone"
                      onChange={handleInput}
                      value={productInput.telephone}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">{errorlist.telephone}</small>
                  </div>

                  <div className="col-md-4 form-group mb-3">
                    <label>Vendor's Email Address</label>
                    <input
                      type="text"
                      name="vendor_email"
                      onChange={handleInput}
                      value={productInput.vendor_email}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.vendor_email}
                    </small>
                  </div>

                  <div className="col-md-4 form-group mb-3">
                    <label>Property Longitude on Map</label>
                    <input
                      type="text"
                      name="long"
                      onChange={handleInput}
                      value={productInput.long}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.long}
                    </small>
                  </div>

                  <div className="col-md-4 form-group mb-3">
                    <label>Property Latitude on Map </label>
                    <input
                      type="text"
                      name="lat"
                      onChange={handleInput}
                      value={productInput.lat}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.lat}
                    </small>
                  </div>

                  <hr />
                </div>

                {/* <h4 class="card-title  mt-5 display-4">FACILITIES AVAILABLE IN PROPERTY (Please select where applicable)</h4>  */}
                <div className="row">
                  <strong class="card-title text-warning">
                    FACILITIES AVAILABLE IN PROPERTY{" "}
                    <span className="text-muted">
                      (Please select where applicable)
                    </span>
                  </strong>
                  <br />
                </div>
                <div className="row mt-3 mb-5">
                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        type="checkbox"
                        id="swimmingpool"
                        name="swimmingpool"
                        onChange={handleInput}
                        value={productInput.swimmingpool}
                      />
                      <label for="swimmingpool" className="text-muted">
                        SWIMMING POOL
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="freeparking"
                        type="checkbox"
                        name="freeparking"
                        onChange={handleInput}
                        value={productInput.freeparking}
                      />
                      <label for="freeparking" className="text-muted">
                        FREE PARKING
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="roomservice"
                        type="checkbox"
                        name="roomservice"
                        onChange={handleInput}
                        value={productInput.roomservice}
                      />
                      <label for="roomservice" className="text-muted">
                        ROOM SERVICE{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="restuarant"
                        type="checkbox"
                        name="restuarant"
                        onChange={handleInput}
                        value={productInput.restuarant}
                      />
                      <label for="restuarant" className="text-muted">
                        ON-SITE RESTAURANT{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="nosmoke"
                        type="checkbox"
                        name="nosmoke"
                        onChange={handleInput}
                        value={productInput.nosmoke}
                      />
                      <label for="nosmoke" className="text-muted">
                        NON-SMOKING ROOMS{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="frdesk"
                        type="checkbox"
                        name="frdesk"
                        onChange={handleInput}
                        value={productInput.frdesk}
                      />
                      <label for="frdesk" className="text-muted">
                        24 HOUR FRONT DESK{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="laundry"
                        type="checkbox"
                        name="laundry"
                        onChange={handleInput}
                        value={productInput.laundry}
                      />
                      <label for="laundry" className="text-muted">
                        LAUNDRY SERVICES{" "}
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 form-group mb-3">
                      <label>
                        No. of BEDs (If not applicable, set default values as
                        zero)
                      </label>
                      <input
                        type="number"
                        name="beds"
                        onChange={handleInput}
                        value={productInput.beds}
                        className="form-control input-lg"
                      />
                      <small className="text-warning">{errorlist.beds}</small>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                      <label>
                        No. of BATHs (If not applicable, set default values as
                        zero)
                      </label>
                      <input
                        type="number"
                        name="baths"
                        onChange={handleInput}
                        value={productInput.baths}
                        className="form-control input-lg"
                      />
                      <small className="text-warning">{errorlist.baths}</small>
                    </div>

                    <div className="col-md-3">
                    <label>
                        Check in
                      </label>
                      {/* <DatePicker 
                      minDate={new Date()} 
                      className="form-control input-lg" 
                      selected={startDate} 
                      onChange={(date) => setStartDate(date)} 
                      value={productInput.checkin}
                      name="checkin"
                      /> */}
                    <input
                        name="checkin"
                        onChange={handleInput}
                        className="form-control input-lg"
                        type="date"
                        value={productInput.checkin}
                      />{" "}
                      <small className="text-warning">{errorlist.checkin}</small>
                    </div>
                    <div className="col-md-3">
                    <label>
                        Check out
                      </label>
                      {/* <DatePicker 
                      minDate={new Date()} 
                      className="form-control input-lg" 
                      selected={startDate1} 
                      onChange={(date) => setStartDate1(date)} 
                      /> */}

                    <input
                        name="checkout"
                        onChange={handleInput}
                        className="form-control input-lg"
                        type="date"
                        value={productInput.checkout}
                      />{" "}
                      <small className="text-warning">{errorlist.checkout}</small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <strong class="card-title text-warning">
                    VIEWERS STATUS{" "}
                    <span className="text-muted">
                      (Please select all checkboxes)
                    </span>
                  </strong>
                  <br />
                </div>
                {/* <h4 class="card-title mt-5 display-4"> (Please select all checkboxes)</h4> <br/> */}
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        type="checkbox"
                        id="featured"
                        name="featured"
                        onChange={handleInput}
                        value={productInput.featured}
                      />
                      <label for="featured" className="text-muted">
                        Featured (checked=shown)
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="popular"
                        type="checkbox"
                        name="popular"
                        onChange={handleInput}
                        value={productInput.popular}
                      />
                      <label for="popular" className="text-muted">
                        Popular (checked=shown)
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="status"
                        type="checkbox"
                        name="status"
                        onChange={handleInput}
                        value={productInput.status}
                      />
                      <label for="status" className="text-muted">
                        Status (checked=Hidden)
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <hr className="mt-4" />
                        <strong class="card-title text-warning">
                          UPLOAD PHOTOS{" "}
                          <span className="text-muted">
                            (Images size should not exceed 2mb)
                          </span>
                        </strong>
                        <br />

                      {/* <small className="text-warning mb-4">
                        Images size should not exceed 2mb
                      </small> */}
                      {/* Image Upload */}

                      <MDBInputGroup
                        className="mb-3 mt-4"
                        textBefore="Upload Property Photo 1"
                        textTag="label"
                        textProps={{ htmlFor: "inputGroupFile01" }}
                      >
                        <input
                          className="form-control  file-upload-info"
                          type="file"
                          id="inputGroupFile01"
                          name="imageFile"
                          accept="image/*"
                          // multiple="multiple"
                          onChange={handleImage}
                        />
                        <small className="text-warning">
                          {errorlist.imageFile}
                        </small>
                      </MDBInputGroup>

                      {/* <MDBInputGroup
                      className="mb-3"
                      textBefore="Upload Property Photo 2"
                      textTag="label1"
                      textProps={{ htmlFor: "inputGroupFile02" }}
                    >
                      <input
                        className="form-control file-upload-info"
                        type="file"
                        id="inputGroupFile02"
                        name="imageFile2"
                        accept="image/*"
                        // multiple="multiple"
                        onChange={handleImage2}

                      />
                      <small className="text-warning">{errorlist.imageFile2}</small>
                    </MDBInputGroup> */}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-outline-warning btn-lg btn-fw px-4 mt-2"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddHouseProduct;
