import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import default_img from "../assets/img/emmy.png";
import vlogo from "../assets/img/logo.png";

import '../App.css'

function Sidebar (props){
  const [adminTest, setAdmin] = useState([]);
  const [categorylist, setCategorylist] = useState([]);

  useEffect(() => {
    axios.get(`/api/log-admin`).then((res) => {
      console.log(res.data.admin);
      if (res.data.status === 200) {
        console.log(res.data.admin);
        setAdmin(res.data.admin);
      }
    });

    axios.get(`/api/all-category`).then((res) => {
      console.log(res.data.category);
      if (res.data.status === 200) {
        setCategorylist(res.data.category);
      }
    });
  }, []);

  // const [isVisible, setVisible] = React.useState(true);
  // function handleClick() {
  //   setVisible(v => !v);
  // }

  console.log(props);
  return (
    <>
          {/* <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at
          commodo nibh, ut maximus turpis. Ut porttitor tempus augue, ac rutrum
          lorem rutrum eget. Donec libero lorem, luctus in maximus at, facilisis
          sit amet sapien. Pellentesque pharetra non urna ac porttitor.
          Phasellus a dignissim augue, rhoncus sagittis ante. Cras quis orci ac
          risus dapibus pellentesque faucibus in leo. Vivamus gravida eros a leo
          luctus, mollis elementum lectus viverra. Vivamus pellentesque pulvinar
          turpis. Cras rutrum justo at mauris aliquam, quis luctus est posuere.
          Quisque erat neque, sollicitudin quis mi et, commodo ultrices magna.
          Etiam varius efficitur elementum. Nam et lacus sit amet mi viverra
          lacinia. Nullam pretium est vitae tortor mollis tempor. Cras tincidunt
          magna eget purus gravida, et ultricies ex consequat.
        </p>
      </div> */}

      
      {/* <nav className={`sidebar sidebar-offcanvas ${'active'}`} id="sidebar">  */}
      <nav className={` sidebar sidebar-offcanvas id="sidebar"
       ${props.data ? 'active' : ''} ${props.data ? 'sidebar-icon-only' : ''}`} >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link className="sidebar-brand brand-logo" to={"/v-admin"}>
            {/* <img src={vlogo} alt="v-hospitality-logo" style={{"width":"50%"}} />  */}
            <strong>V-Hospitality</strong>
          </Link>
          <Link className="sidebar-brand brand-logo-mini" to={"/v-admin"}>
            <img src={vlogo} alt="v-hospitality-logo" />
          </Link>
        </div>
        <ul className="nav">
          <li className="nav-item nav-category">
            <span className="nav-link">Admin Dashboard</span>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/v-admin/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item nav-category">
            <span className="nav-link">Properties Category</span>
          </li>

          <li className="nav-item menu-items">
            <Link className="nav-link" to={"/v-admin/view-category"}>
              <span className="menu-icon">
                <i className="mdi mdi-playlist-play"></i>
              </span>
              <span className="menu-title">Manage All Category</span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to={"/v-admin/add-category"}>
              <span className="menu-icon">
                <i className="mdi mdi-format-align-center"></i>
              </span>
              <span className="menu-title">Add New</span>
            </Link>
          </li>

          <li className="nav-item nav-category">
            <span className="nav-link">Manage Properties</span>
          </li>

          <li className="nav-item menu-items">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">Properties</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                {/* <hr className=""/> */}

                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to={"/v-admin/view-houseproduct"}>
                    Manage All Property
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to={"/v-admin/add-houseproduct"}>
                    Add New 
                  </Link>
                </li>

                <li className="nav-item nav-category">
                  <span className="nav-link"> </span>
                </li>

                {categorylist.map((item) => {
                  return (
                    <>
                      <li className="nav-item">
                        {" "}
                        <Link
                          className="nav-link"
                          to={`/v-admin/open-category/${item.id}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    </>
                  );
                })}

                <li className="nav-item nav-category">
                  <span className="nav-link"> </span>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item menu-items">
            <Link className="nav-link" to={"/v-admin/avail-props"}>
              <span className="menu-icon">
                <i className="mdi mdi-call-received"></i>
              </span>
              <span className="menu-title">Available Properties</span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to={"/v-admin/booked-props"}>
              <span className="menu-icon">
                <i className="mdi mdi-call-made"></i>
              </span>
              <span className="menu-title">Booked Properties</span>
            </Link>
          </li>

          <li className="nav-item menu-items">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#auth"
              aria-expanded="false"
              aria-controls="auth"
            >
              <span className="menu-icon">
                <i className="mdi mdi-currency-ngn"></i>
              </span>
              <span className="menu-title">Transactions</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="auth">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to={"/v-admin/all-trans"}>
                    {" "}
                    All Transactions{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to={"/v-admin/coa"}>
                    {" "}
                    Payments on Arrival{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to={"/v-admin/paystack"}>
                    {" "}
                    By Paystack{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item nav-category">
            <span className="nav-link">More</span>
          </li>

          <li className="nav-item menu-items">
            <Link className="nav-link" to={"/v-admin/settings"}>
              <span className="menu-icon">
                <i className="mdi mdi-settings"></i>
              </span>
              <span className="menu-title">Account Settings</span>
            </Link>
          </li>
        </ul>
        <p>{props.data} </p>
        {/* <button
            class={`navbar-toggler navbar-toggler-right d-lg-none align-self-center ${isVisible ? 'active' : 'active'}`}
            type="button"
            data-toggle="offcanvas"
            onClick={handleClick}
          >
            <span class="mdi mdi-format-line-spacing" ></span>
          </button> */}
      </nav>
    </>
  );
};

export default Sidebar;
