import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import swal from "sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Swal from 'sweetalert2'
import "sweetalert2/dist/sweetalert2.css";

import "../../App.css";

// const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;

function AvailableProps() {
  const [q, setQ] = useState("");
  const [searchParam] = useState(["brand", "name", "status"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const [loading, setLoading] = useState(true);
  const [viewProduct, setProduct] = useState([]);
  const [categorylist, setCategorylist] = useState([]);

  const data = Object.values(viewProduct);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  useEffect(() => {
    document.title = "view home category";
    axios.get("/api/view-avail").then((res) => {
      if (res.data.status === 200) {
        // console.log(res.data.houses);
        setProduct(res.data.houses);
        setLoading(false);
      }
    });

    axios.get(`/api/all-category`).then((res) => {
      console.log(res.data.category);
      if (res.data.status === 200) {
        setCategorylist(res.data.category);
      }
    });
  }, []);

  const deleteCategory = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#000',
      cancelButtonColor: '#fff',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        thisClicked.innerText = "Deleting";

        axios.delete(`/api/delete-product/${id}`).then((res) => {
          if (res.data.status === 200) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            thisClicked.closest("tr").remove();
          } else if (res.data.status === 404) {
            swal("Success", res.data.message, "success");
            thisClicked.innerText = "Delete";
          }
        });
      } else {
        Swal.fire(' Cancelled', '', 'error')
      }
      
    })

  };

  var display_Productdata = "";
  if (loading) {
    return (
      <div classNameName="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } else {
    display_Productdata = search(data).map((item, idx) => {
      return (
        <>
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>
              <div classNameName="d-flex align-items-center">
                <img
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                  alt={item.name}
                  style={{ width: "45px", height: "45px" }}
                  classNameName="rounded-circle"
                />{" "}
                <div classNameName="">
                  {/* <p classNameName="fw-bold mb-1">{item.category.name}</p> */}{" "}
                  <small classNameName="ml-2 text-muted mb-0 flex-grow">
                    {" "}
                    {item.slug}
                  </small>
                </div>
              </div>
            </td>

            <td>{item.brand}</td>
            <td>{item.selling_price}</td>
            <td>
              {" "}
              {item.status === 0 ? (
                <div className="badge badge-outline-success">Available</div>
              ) : (
                <div className="badge badge-outline-danger">Not Available</div>
              )}{" "}
            </td>
            <td>
              <Link to={`edit-product/${item.id}`}>
                <button
                  type="button"
                  className="btn btn-inverse-warning btn-fw"
                >
                  Edit
                </button>
              </Link>
            </td>
            <td>
            <div>
              <button
                type="button"
                class="btn btn-inverse-danger mr-3"
                onClick={(e) => deleteCategory(e, item.id)}
              >
                Delete
              </button>
              <ToastContainer />
              </div>
            </td>
          </tr>
        </>
      );
    });
  }
  return (
    <>
      <div className="row mb-3">
        <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search w-100">
          <input
            type="search"
            className="form-control form-control-lg input_border"
            placeholder="Search by name or property type, or vendor..."
            aria-label="Search"
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
        </form>
      </div>

      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Available Properties</h4>

              {/* <div className="row ">
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="exampleFormControlSelect1" className=" mt-6">
                      Based on Property Type
                    </label>
                    <select
                      className="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                    >
                      <option value="All">All Types</option>
                      {categorylist.map((item) => {
                        return (
                          <>
                            {" "}
                            <option>{item.name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="exampleFormControlSelect1" className=" mt-6">
                      Based on Property Type
                    </label>
                    <select
                      className="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                    >
                      <option value="All">All Types</option>
                      {categorylist.map((item) => {
                        return (
                          <>
                            {" "}
                            
                            <option value={item.name}>{item.name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="exampleFormControlSelect1" className=" mt-6">
                      Based on Property Type
                    </label>
                    <select
                      className="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                    >
                      <option value="All">All Types</option>
                      {categorylist.map((item) => {
                        return (
                          <>
                            {" "}
                            <option>{item.name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div> */}

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    Available
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/v-admin/booked-props">Booked</Link>
                  </li>
                </ol>
              </nav>

              <div className="table-responsive">
                <table className="table  table-dark table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Property Name</th>
                      <th>Property Type</th>
                      <th>Selling Price</th>
                      <th>Status</th>
                      <th>Make Edits/Change</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Add here */}
                    {display_Productdata}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableProps;
