import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import ReactLoading from "react-loading";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;

function EditCategory(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [picture, setPicture] = useState({});
  const [categoryInput, setCategory] = useState({
    slug: "",
    name: "",
    description: "",

    meta_title: "",
    meta_keyword: "",
    meta_descrip: "",
  });
  const [error, setError] = useState([]);
  const handleInput = (e) => {
    e.persist();
    setCategory({ ...categoryInput, [e.target.name]: e.target.value });
  };

  const [allcheckbox, setCheckboxes] = useState([]);
  const handleCheckbox = (e) => {
    e.persist();
    setCheckboxes({ ...allcheckbox, [e.target.name]: e.target.checked });
  };

  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };

  useEffect(() => {
    document.title = "Edit category";
    const category_id = props.match.params.id;
    axios.get(`/api/edit-category/${category_id}`).then((res) => {
      console.log(res.data.category);
      if (res.data.status === 200) {
        setCategory(res.data.category);
        setCheckboxes(res.data.category);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/v-admin/view-category");
      }
      setLoading(false);
    });

    // setTimeout(() => {
    //     setLoading(false);
    //   }, 800)
  }, [props.match.params.id]);

  const updateCategory = (e) => {
    e.preventDefault();

    const category_id = props.match.params.id;

    // const data = categoryInput;
    const formData = new FormData();

    formData.append("image", picture.image);
    formData.append("slug", categoryInput.slug);
    formData.append("name", categoryInput.name);
    formData.append("description", categoryInput.description);

    formData.append("meta_title", categoryInput.meta_title);
    formData.append("meta_keyword", categoryInput.meta_keyword);
    formData.append("meta_descrip", categoryInput.meta_descrip);
    formData.append("status", allcheckbox.status ? "1" : "0");

    axios.post(`/api/update-category/${category_id}`, formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setError([]);
        history.push("/v-admin/view-category");
      } else if (res.data.status === 422) {
        swal("All fields are Mandatory", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/v-admin/view-category");
      }
    });
  };

  // const showToastMessage = () => {
  //   toast.success("Success Notification !", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid px-4">
        <div className="card mt-4">
          <div className="card-header">
            <h4>Edit Property  Category</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  Edit Property
                </li>
                <li class="breadcrumb-item">
                  <Link to="/v-admin/view-category" href="#">
                    View Property
                  </Link>
                </li>
              </ol>
            </nav>
          </div>

          <div className="card-body">
            <form onSubmit={updateCategory} encType="multipart/form-data">
              <div className="row">
                <div className="form-group mb-3 col-12">
                  <label>Slug</label>
                  <input
                    type="text"
                    name="slug"
                    onChange={handleInput}
                    value={categoryInput.slug}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{error.slug}</small>
                </div>
                <div className="form-group mb-3 col-12">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInput}
                    value={categoryInput.name}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{error.name}</small>
                </div>

                <div class="form-group mb-3 col-12">
                  <label for="description"> Description</label>
                  <textarea
                    name="description"
                    onChange={handleInput}
                    value={categoryInput.description}
                    className="form-control input-lg"
                    rows="6"
                    placeholder="Write your message..."
                  ></textarea>
                </div>

                <div className="form-group mb-3 col-12">
                  <label>Meta Title</label>
                  <input
                    type="text"
                    name="meta_title"
                    onChange={handleInput}
                    value={categoryInput.meta_title}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{error.meta_title}</small>
                </div>

                <div class="form-group mb-3 col-12">
                  <label for="meta_keyword">Meta Keywords</label>
                  <textarea
                    rows={6}
                    name="meta_keyword"
                    onChange={handleInput}
                    value={categoryInput.meta_keyword}
                    className="form-control input-lg"
                    placeholder="Write your message..."
                  ></textarea>
                </div>

                <div class="form-group mb-3 col-12">
                  <label for="meta_descrip">Meta Description</label>
                  <textarea
                    rows={6}
                    name="meta_descrip"
                    onChange={handleInput}
                    value={categoryInput.meta_descrip}
                    className="form-control input-lg"
                    placeholder="Write your message..."
                  ></textarea>
                </div>

                <div className="col-md-4 mb-3 col-12">
                  <div class="zinput zcheckbox">
                    <input
                      type="checkbox"
                      id="status"
                      name="status"
                      onChange={handleCheckbox}
                      defaultChecked={allcheckbox.status === 1 ? true : false}
                    />
                    <label for="status" className="text-muted">
                      Status 0=shown/1=hidden
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 form-group mb-3">
                  <label>Image</label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleImage}
                    className="form-control form-control-lg"
                  />
                  <img
                    src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${categoryInput.image}`}
                    width="50px"
                    alt={categoryInput.name}
                  />
                  <small className="text-warning">{error.image}</small>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-outline-warning btn-lg btn-fw px-4 mt-2"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* <div>
        <button onClick={showToastMessage}>Notify</button>
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      </div> */}
    </>
  );
}

export default EditCategory;
