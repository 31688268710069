
// import  Dashboard  from "../components/admin/Dashboard";
// import  Profile  from "../components/admin/Profiles/Profile";
// import  Category  from "../components/admin/Category";
// import  ViewCategory  from "../components/admin/ViewCategory";
// import  EditCategory  from "../components/admin/EditCategory";
// import  HouseProduct  from "../components/admin/HouseProduct/AddHouseProduct";
// import  ViewHouseProduct  from "../components/admin/HouseProduct/ViewHouseProduct";
// import  EditProduct  from "../components/admin/HouseProduct/EditProduct";
// import  Paystack  from "../components/admin/OnlineTransactions/Paystack";
// import  CashOnArrival  from "../components/admin/OnlineTransactions/CashOnArrival";
// import  AllTransaction  from "../components/admin/OnlineTransactions/AllTransaction";
// import TransactionDetails from "../components/admin/OnlineTransactions/TransactionDetails";

import  Dashboard  from "../v-admin-components/Dashboard";
import  Profile  from "../v-admin-components/Profiles/Profile";

import  AllTransaction  from "../v-admin-components/OnlineTransactions/AllTransaction";
import  CashOnArrival  from "../v-admin-components/OnlineTransactions/CashOnArrival";
import  Paystack  from "../v-admin-components/OnlineTransactions/Paystack";
import  TransactionDetails  from "../v-admin-components/OnlineTransactions/TransactionDetails";

import ViewHouseProduct from "../v-admin-components/HouseProduct/ViewHouseProduct";
import AddHouseProduct from "../v-admin-components/HouseProduct/AddHouseProduct";
import EditProduct from "../v-admin-components/HouseProduct/EditProduct";

import Category from '../v-admin-components/HouseCategory/Category'
import ViewCategory from '../v-admin-components/HouseCategory/ViewCategory'
import EditCategory from '../v-admin-components/HouseCategory/EditCategory'

import BookedProps from "../v-admin-components/Available_Booked_Props/BookedProps";
import AvailableProps from "../v-admin-components/Available_Booked_Props/AvailableProps";

import OpenPropCategory from '../v-admin-components/OpenPropCategory/OpenPropCategory'
import BookedPropsCategory from "../v-admin-components/OpenPropCategory/BookedProps";


const routes = [ 
    
    {path: '/', exact:true,  name: "Dashboard", component:Dashboard},
    // {path: '/v-admin', exact:true, name: "v-admin"},
    {path: '/v-admin/dashboard', exact:true, name: "Dashboard", component:Dashboard},

    {path: '/v-admin/settings', exact:true, name: "Profile", component:Profile},

    {path: '/v-admin/all-trans', exact:true, name: "AllTransaction", component:AllTransaction},
    {path: '/v-admin/paystack', exact:true, name: "Paystack", component:Paystack},
    {path: '/v-admin/coa', exact:true, name: "CashOnArrival", component:CashOnArrival},
    {path: '/v-admin/view-tran-details/:user_id', exact:true, name: "TransactionDetails",  component: TransactionDetails},
    
    {path: '/v-admin/add-houseproduct', exact:true, name: "AddHouseProduct", component:AddHouseProduct},
    {path: '/v-admin/view-houseproduct', exact:true, name: "ViewHouseProduct", component:ViewHouseProduct},
    {path: '/v-admin/edit-product/:id', exact:true, name: "EditProduct", component:EditProduct},

    {path: '/v-admin/add-category', exact:true, name: "Category",  component: Category},
    {path: '/v-admin/view-category', exact:true, name: "ViewCategory",  component: ViewCategory},
    {path: '/v-admin/open-category/:id', exact:true, name: "OpenPropCategory",  component: OpenPropCategory},
    {path: '/v-admin/open-category/booked/:id', exact:true, name: "BookedPropsCategory",  component: BookedPropsCategory},
    {path: '/v-admin/edit-category/:id', exact:true, name: "EditCategory",  component: EditCategory},

    {path: '/v-admin/booked-props', exact:true, name: "BookedProps",  component: BookedProps},
    {path: '/v-admin/avail-props', exact:true, name: "AvailableProps",  component: AvailableProps},
    
    
    // {path: '/admin', exact:true, name: "Admin"},
    // {path: '/admin/dashboard', exact:true, name: "Dashboard", component: Dashboard},
    // {path: '/admin/add-category', exact:true, name: "Category",  component: Category},
    // {path: '/admin/view-category', exact:true, name: "ViewCategory",  component: ViewCategory},
    // {path: '/admin/edit-category/:id', exact:true, name: "EditCategory",  component: EditCategory},
    // {path: '/admin/profile', exact:true, name: "Profile",  component: Profile},

    // {path: '/admin/add-houseproduct', exact:true, name: "HouseProduct",  component: HouseProduct},
    // {path: '/admin/view-houseproduct', exact:true, name: "ViewHouseProduct",  component: ViewHouseProduct},
    // {path: '/admin/edit-product/:id', exact:true, name: "EditProduct",  component: EditProduct},


    // {path: '/admin/all-trans', exact:true, name: "AllTransaction",  component: AllTransaction},
    // {path: '/admin/paystack', exact:true, name: "Paystack",  component: Paystack},
    // {path: '/admin/coa', exact:true, name: "CashOnArrival",  component: CashOnArrival},
    // {path: '/admin/view-tran-details/:user_id', exact:true, name: "TransactionDetails",  component: TransactionDetails},
]

export default routes;