import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import swal from "sweetalert";

import Swal from 'sweetalert2'
import "sweetalert2/dist/sweetalert2.css";

import "../../App.css";

import { ToastContainer, toast } from "react-toastify";


// const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;

function BookedProps() {
  const [q, setQ] = useState("");
  const [searchParam] = useState(["brand", "name", "status"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const [loading, setLoading] = useState(true);
  const [viewProduct, setProduct] = useState([]);

  const data = Object.values(viewProduct);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }


  useEffect(() => {
    document.title = "view home category";
    axios.get("/api/view-booked").then((res) => {
      if (res.data.status === 200) {
        // console.log(res.data.houses);
        setProduct(res.data.houses);
        setLoading(false);
      }
    });
  }, []);

  const deleteTestCategory = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Removing";

    axios.post(`/api/remove-product/${id}`).then((res) => {
      if (res.data.status === 200) {
        toast(`🦄👌 ${res.data.message}!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        // swal("Success", res.data.message, "success");
        thisClicked.closest("tr").remove();
      } else if (res.data.status === 404) {
        swal("Success", res.data.message, "success");
        thisClicked.innerText = "Delete";
      }
    });
  };

  const deleteCategory = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Deleting";

    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#000',
    //   cancelButtonColor: '#fff',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     Swal.fire(
    //       'Deleted!',
    //       'Your file has been deleted.',
    //       'success'
    //     )
    //   }
    // })

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     confirmButtonColor: "#ccc",
  //     cancelButtonColor: "#d33",
  //     showConfirmButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: "OK",
  //     cancelButtonText: "Cancel",
  //     icon: 'warning',

  // }
  // ).then((result) => {
  //     if (result.isConfirmed) {

  //         Swal.fire('Nice to meet you', '', 'success');

      // } else
      //     Swal.fire(' Cancelled', '', 'error')

  // })

    

    // axios.delete(`/api/delete-product/${id}`).then((res) => {
    //   if (res.data.status === 200) {
    //     toast(`🦄👌 ${res.data.message}!`, {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "dark",
    //       });
    //     // swal("Success", res.data.message, "success");
    //     thisClicked.closest("tr").remove();
    //   } else if (res.data.status === 404) {
    //     swal("Success", res.data.message, "success");
    //     thisClicked.innerText = "Delete";
    //   }
    // });
  };

  var display_Productdata = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } else {
    display_Productdata = search(data).map((item, idx) => {
      return (
        <>
          <tr key={item.id}>
          <td>{item.id}</td>
            <td>
              <div className="d-flex align-items-center">
                <img
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                  alt={item.name}
                  style={{ width: "45px", height: "45px" }}
                  className="rounded-circle"
                />{" "} 
                <div className="">
                  {/* <p className="fw-bold mb-1">{item.category.name}</p> */}
                  {" "}<small className="ml-2 text-muted mb-0 flex-grow"> {item.slug}</small>
                </div>
              </div>
            </td>
            
            <td>{item.brand}</td>
            <td>{item.selling_price}</td>
            <td>
            {" "}
              {item.status === 0 ? (
                <div class="badge badge-outline-success">Available</div>
              ) : (
                <div class="badge badge-outline-danger">Not Available</div>
              )}{" "}
            
            </td>
            <td>
            <Link to={`edit-product/${item.id}`}>
              <button type="button" class="btn btn-inverse-warning btn-fw">Edit</button>
              </Link>
              
            </td>
            <td>
            <div>
              <button type="button" 
                class="btn btn-outline-warning mr-3" onClick={(e) => deleteTestCategory(e, item.id)}>Make Available</button>
                        <ToastContainer />
              </div>
              
            </td>
          </tr>

        </>
      );
    });
  }




  if (display_Productdata.length > 0) {
    return (
      <>
  
  <div class="row mb-3">
        <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search w-100">
          <input
            type="search"
            className="form-control form-control-lg input_border"
            placeholder="Search by name or property type, or vendor..."
            aria-label="Search"
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
        </form>
        </div>
  
  <div class="row ">
          <div class="col-12 grid-margin">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Booked Properties</h4>
  
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/v-admin/avail-props" href="#">Available </Link ></li>
                    <li class="breadcrumb-item active" aria-current="page">Booked </li>
                  </ol>
                </nav>
  
  
                <div class="table-responsive">
                  <table class="table  table-dark table-hover">
                    <thead>
                      <tr>
                      
                      <th>ID</th>
                      <th>Property Name</th>
                      <th>Property Type</th>
                      <th>Selling Price</th>
                      <th>Status</th>
                      <th>Make Edits/Change</th>
                      <th>Make Property Available</th>
  
                      </tr>
                    </thead>
                    <tbody>
                      {/* Add here */}
                      {display_Productdata}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
  
  
  
  
  
      </>
    );
  } else {
    return (
      <>
        <div class="row mb-3">
          <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search w-100">
            <input
              type="search"
              className="form-control form-control-lg input_border"
              placeholder="Search by name or property type, or vendor..."
              aria-label="Search"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </form>
        </div>

        <div class="row ">
          <div class="col-12 grid-margin">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Booked Properties</h4>

                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    
                    <li class="breadcrumb-item">
                      <Link
                        to={`/v-admin/avail-props`}
                      >
                        Available
                      </Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Booked
                    </li>
                  </ol>
                </nav>

                <div class="table-responsive">
                  <table class="table  table-dark table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Property Name</th>
                        <th>Property Type</th>
                        <th>Selling Price</th>
                        <th>Status</th>
                        <th>Make Edits/Change</th>
                        <th>Make Property Available</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Add here */}
                      
                    </tbody>
                  </table>
                </div>

                <div className="row text-center mt-4">
                        <div className="col-12 ">
                        <i className="mdi mdi-file-excel-box icon-lg"></i>
                        
                        <p className="text-center">No Property Booked Yet</p>
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

















//   return (
//     <>

// <div class="row mb-3">
//       <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search w-100">
//         <input
//           type="search"
//           className="form-control form-control-lg input_border"
//           placeholder="Search by name or property type, or vendor..."
//           aria-label="Search"
//           value={q}
//           onChange={(e) => setQ(e.target.value)}
//         />
//       </form>
//       </div>

// <div class="row ">
//         <div class="col-12 grid-margin">
//           <div class="card">
//             <div class="card-body">
//               <h4 class="card-title">Booked Properties</h4>

//               <nav aria-label="breadcrumb">
//                 <ol class="breadcrumb">
//                   <li class="breadcrumb-item"><Link to="/v-admin/avail-props" href="#">Available </Link ></li>
//                   <li class="breadcrumb-item active" aria-current="page">Booked </li>
//                 </ol>
//               </nav>


//               <div class="table-responsive">
//                 <table class="table  table-dark table-hover">
//                   <thead>
//                     <tr>
                    
//                     <th>ID</th>
//                     <th>Property Name</th>
//                     <th>Property Type</th>
//                     <th>Selling Price</th>
//                     <th>Status</th>
//                     <th>Make Edits/Change</th>
//                     <th>Make Property Available</th>

//                     </tr>
//                   </thead>
//                   <tbody>
//                     {/* Add here */}
//                     {display_Productdata}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>







//     </>
//   );
}

export default BookedProps;
