import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import swal from "sweetalert";

import Swal from 'sweetalert2'
import "sweetalert2/dist/sweetalert2.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;

function ViewCategory() {
  const [q, setQ] = useState("");
  const [searchParam] = useState([ "name"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const [loading, setLoading] = useState(true);
  const [categorylist, setCategorylist] = useState([]);

  const data = Object.values(categorylist);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  useEffect(() => {
    document.title = "view category";
    axios.get(`/api/view-category`).then((res) => {
      console.log(res.data.category);
      if (res.status === 200) {
        setCategorylist(res.data.category);
      }
      setLoading(false);
    });
  }, []);

  const deleteCategory = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;

    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: [
        'No, cancel it',
        'Yes, delete it'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {
      if (isConfirm) {
        swal({
          title: 'Deleted!',
          text: 'This property category has been deleted.',
          icon: 'success'
        }).then(function() {
          thisClicked.innerText = "Deleting";
          axios.delete(`/api/delete-category/${id}`).then((res) => {
            if (res.data.status === 200) {
              thisClicked.closest("tr").remove();
            } else if (res.data.status === 404) {
              swal("Success", res.data.message, "success");
              thisClicked.innerText = "Delete";
            }
          });
        });
      } else {
        swal("Cancelled", " ", "error");
      }
    })
    


    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#000',
    //   cancelButtonColor: '#fff',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     thisClicked.innerText = "Deleting";

    //     axios.delete(`/api/delete-category/${id}`).then((res) => {
    //       if (res.data.status === 200) {
    //         Swal.fire(
    //           'Deleted!',
    //           'Your file has been deleted.',
    //           'success'
    //         )
    //         thisClicked.closest("tr").remove();
    //       } else if (res.data.status === 404) {
    //         swal("Success", res.data.message, "success");
    //         thisClicked.innerText = "Delete";
    //       }
    //     });
    //   } else {
    //     Swal.fire(' Cancelled', '', 'error')
    //   }
      
    // })

    // axios.delete(`/api/delete-category/${id}`).then((res) => {
    //   if (res.data.status === 200) {
    //     thisClicked.closest("tr").remove();
    //   } else if (res.data.status === 404) {
    //     swal("Success", res.data.message, "success");
    //     thisClicked.innerText = "Delete";
    //   }
    // });
    // axios.delete(`/api/delete-category/${id}`).then((res) => {
    //   if (res.data.status === 200) {
    //     toast(`🦄👌 ${res.data.message}!`, {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "dark",
    //       });
    //     thisClicked.closest("tr").remove();
    //   } else if (res.data.status === 404) {
    //     swal("Success", res.data.message, "success");
    //     thisClicked.innerText = "Delete";
    //   }
    // });
  };

  var viewcategory_HTMLTABLE = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } else {
    viewcategory_HTMLTABLE = search(data).map((item, idx) => {
      return (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.name}</td>
          <td>
            {item.status === 0 ? (
              <div class="badge badge-outline-success">Active</div>
            ) : (
              <div class="badge badge-outline-warning">Not Active</div>
            )}
          </td>
          <td>
          <Link to={`edit-category/${item.id}`}>
              <button type="button" class="btn btn-inverse-warning btn-fw">Edit</button>
              </Link>
           
          </td>
          <td>
              <div>
              <button type="button" 
                class="btn btn-inverse-danger mr-3" onClick={(e) => deleteCategory(e, item.id)}>Delete</button>
                        <ToastContainer />
              </div>

          </td>
        </tr>
      );
    });
  }

  return (
    <>

<div class="row mb-3">
      <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search w-100">
        <input
          type="search"
          className="form-control form-control-lg input_border"
          placeholder="Search by name or property type..."
          aria-label="Search"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
      </form>
      </div>

      <div class="row ">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">All Property Category</h4>

              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active" aria-current="page">View Category</li>
                  <li class="breadcrumb-item"><Link to="/v-admin/add-category" href="#">Add Category</Link ></li>
                </ol>
              </nav>


              <div class="table-responsive">
                <table class="table  table-dark table-hover">
                  <thead>
                    <tr>
                    
                    <th>ID</th>
                    <th>Name</th>
                    <th>Property Type</th>
                    <th>Status</th>
                    <th>Make Edits/Change</th>
                    <th>Delete</th>

                    </tr>
                  </thead>
                  <tbody>
                    {/* Add here */}
                    {viewcategory_HTMLTABLE}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`} */}
      {/* TEST */}
    </>
  );
}

export default ViewCategory;


