import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Footer from './Footer';
import Sidebar from './SideBar';
import Navbar from './Navbar';

// import Navbar from './Navbar';
// import Sidebar from './Sidebar';
// import Footer from './Footer';

import routes from '../routes/routes';


const MasterLayout = (...props) => {

    // console.log(props.data)

    
    

    return (
        <>
                <div class="container-scroller">
                    {/* <!-- partial:partials/_sidebar.html --> */}
                    <nav class="sidebar sidebar-offcanvas" id="sidebar">
                        <Sidebar />
                    </nav>
                    {/* <!-- partial --> */}
                    <div class={`container-fluid page-body-wrapper `}>
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <nav class="navbar p-0 fixed-top d-flex flex-row">
                            <Navbar />
                        </nav>
                        {/* <!-- partial --> */}
                        <div class="main-panel">
                        <div class="content-wrapper">
                        <Switch>                          
                        <Switch>
                            {routes.map((route, idx) => {
                                return (
                                    route.component && (
                                        <Route 
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={(props) => (
                                                <route.component {...props} />
                                            )}
                                        />
                                    )
                                )
                            })}
                            <Redirect from="/" to="/v-admin/dashboard" />
                        </Switch>
                        </Switch>
                        </div>
                        {/* <!-- content-wrapper ends --> */}
                        {/* <!-- partial:partials/_footer.html --> */}
                            <Footer />
                        {/* <!-- partial --> */}
                        </div>
                        {/* <!-- main-panel ends --> */}
                    </div>
                    {/* <!-- page-body-wrapper ends --> */}
                    </div>
        </>
    );

}

export default MasterLayout;
