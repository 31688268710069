import React , { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import "bootstrap/dist/js/bootstrap.bundle.js";

import '../App.css'

import default_img  from '../assets/img/emmy.png'

import vlogo  from '../assets/img/logo.png'


import '../../node_modules/jvectormap/jquery-jvectormap.css'
import '../../node_modules/@mdi/font/css/materialdesignicons.min.css'
import '../../node_modules/flag-icon-css/css/flag-icon.min.css'
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css'
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css'

// import '../../node_modules/chart.js/dist/Chart.min.js'
// import '../../node_modules/progressbar.js/dist/progressbar.min.js'
// import '../../node_modules/jvectormap/jquery-jvectormap'
// import '../../node_modules/owl.carousel/dist/owl.carousel.js'
// import '../../node_modules/jquery/dist/jquery'
// import '../../node_modules'







import "../App.css";
import Sidebar from "./SideBar";
import MasterLayout from "./MasterLayout";

function Navbar () {
  const history = useHistory();

  const logoutSubmit = (e) => {
    e.preventDefault();

    axios.post(`/api/logout`).then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        swal("Success", res.data.message, "success");
        history.push("/login");
      }
    });
  };

  const [adminTest, setAdmin] = useState([]);
  useEffect(() => {
    axios.get(`/api/log-admin`).then((res) => {
      console.log(res.data.admin);
      if (res.data.status === 200) {
        console.log(res.data.admin);
        setAdmin(res.data.admin);
      }
    });
  }, []);

  // function handleClick() {
    //   setVisible(v => !v);
    //   console.log("hi")
    // }
    
  const [isVisible, setVisible] = useState(false);
  const handleClick = () => {  
    // setVisible({data: `true`}); 
    setVisible(isVisible => !isVisible);
    console.log(`isVisible is ${isVisible}`)
   };

  const [isIcon, setIcon] = useState(false);
  const handleClickIcon = () => {  
    setIcon(isIcon => !isIcon);
    console.log(`isIcon is ${isIcon}`)
   };


  // const [state, setstate] = useState({data:""})
  
  //   const changeState = () => {  
  //       setstate({data:`state/props of parent component 
  //       is send by onClick event to another component`}); 
  //      };
  

  return (
    <>
      <nav class={`navbar p-0 fixed-top d-flex flex-row `}>
        <div class="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link class="navbar-brand brand-logo-mini" to={'/v-admin'}>
            {/* <img src={vlogo} alt="v-hospitality-logo" /> */}
            <strong>V-Hospitality</strong>
          </Link>
        </div>
        <div class="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          {/* <Sidebar data={isIcon} /> */}
          <button
            class="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            // onClick={handleClickIcon}

          >
            <span class="mdi mdi-menu"></span>
          </button>
          <ul class="navbar-nav w-100">
            {/* <li class="nav-item ">VHospitality</li> */}
            <li class="nav-item w-100">
              <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search products"
                />
              </form>
            </li>
          </ul>
          <ul class="navbar-nav navbar-nav-right">
            
            
            <li class="nav-item dropdown border-left">
              <a
                class="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i class="mdi mdi-bell"></i>
                <span class="count bg-danger"></span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <h6 class="p-3 mb-0">Notifications</h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-dark rounded-circle">
                      <i class="mdi mdi-calendar text-success"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject mb-1">Event today</p>
                    <p class="text-muted ellipsis mb-0">
                      {" "}
                      Just a reminder that you have an event today{" "}
                    </p>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-dark rounded-circle">
                      <i class="mdi mdi-settings text-danger"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject mb-1">Settings</p>
                    <p class="text-muted ellipsis mb-0"> Update dashboard </p>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-dark rounded-circle">
                      <i class="mdi mdi-link-variant text-warning"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject mb-1">Launch Admin</p>
                    <p class="text-muted ellipsis mb-0"> New admin wow! </p>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <p class="p-3 mb-0 text-center">See all notifications</p>
              </div>
            </li>



            <li class="nav-item dropdown">
              <a
                class="nav-link"
                id="profileDropdown"
                href="#"
                data-toggle="dropdown"
              >

{adminTest.map((item, idx) => {
               return (
               <>
               <div class="navbar-profile" key={idx}>
               <div className="count-indicator">
                  <img
                    className="img-xs rounded-circle "
                    // src={`${default_img}`}
                    src={ item.image === "" ? 
                    `${default_img}` : `${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`
                          }
                    
                    alt=""
                  />
                  <span className="count bg-success" style={{"margin-left": "6px", "margin-top": "20px"}}></span>
                </div>
                  <p class="mb-0 d-none d-sm-block navbar-profile-name">
                  {item.firstname} {item.lastname}
                  </p>
                  <i class="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>

               </>
               )
                
            })}


                
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="profileDropdown"
              >
                <h6 class="p-3 mb-0">Profile</h6>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item preview-item" to={'/v-admin/settings'}>
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-dark rounded-circle">
                      <i class="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject mb-1">Account Settings</p>
                  </div>
                </Link>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item preview-item" onClick={logoutSubmit} >
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-dark rounded-circle">
                      <i class="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <p class="preview-subject mb-1">Log out</p>
                  </div>
                </Link>
                <div class="dropdown-divider"></div>
              </div>
            </li>
          </ul>
          <button
            class={`navbar-toggler navbar-toggler-right d-lg-none align-self-center `}
            type="button"
            data-toggle="offcanvas"
            // onClick={changeState}
            onClick={handleClick}
          >
          <Sidebar data={isVisible} />
            {/* {isVisible} */}
            <span class="mdi mdi-format-line-spacing" ></span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
