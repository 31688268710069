import React, { useState, useEffect } from "react";

import axios from "axios";
import swal from "sweetalert";
import { useHistory, Link } from "react-router-dom";

import "../../App.css";
// import Navbar from "../../../layouts/frontend/Navbar";

function Register() {
  const history = useHistory();
  const [registerInput, setRegister] = useState({
    firstname: "",
    lastname: "",
    email: "",
    zipcode: "",
    address1: "",
    address2: "",
    password1: "",
    password2: "",
    bio: "",
    error_list: [],
  });

  useEffect(() => {
    document.title = "register";
  }, []);

  const handleInput = (e) => {
    e.persist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstname: registerInput.firstname,
      lastname: registerInput.lastname,
      email: registerInput.email,
      address1: registerInput.address1,
      address2: registerInput.address2,
      password1: registerInput.password1,
      password2: registerInput.password2,
      zipcode: registerInput.zipcode,
      bio: registerInput.bio,
      image: ""
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/register", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          history.push("/");
        } else if (res.data.status === 401)
        {
            swal("Error", res.data.message, "error");

        }
        
        else {
          setRegister({
            ...registerInput,
            error_list: res.data.validation_errors,
          });
        }
      });
    });
  };

  return (
    <div>

<div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="row w-100 m-0">
          <div class="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
            <div class="card col-lg-4 mx-auto">
              <div class="card-body px-5 py-5">
                <h3 class="card-title text-left mb-3">ADMIN - Register</h3>
                <form>
              
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Email</label>
                  <input
                    class="form-control mb-3 input-lg"
                    name="email" 
                    type="email"
                    placeholder=""
                    onChange={handleInput} value={registerInput.email}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.email}</label>
                </div>


                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Password</label>
                  <input
                    class="form-control mb-3 input-lg"
                    type="password"
                    name="password1" 
                    placeholder=""
                    onChange={handleInput} value={registerInput.password1}
                  />{""}
                  <label className='span_label mb-2'>{registerInput.error_list.password1}</label>
                </div>

                  <div class="form-group d-flex align-items-center justify-content-between">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input"/> Remember me </label>
                    </div>
                    <a href="#" class="forgot-pass">Forgot password</a>
                  </div>
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary btn-block enter-btn">Login</button>
                  </div>
                  {/* <div class="d-flex">
                    <button class="btn btn-facebook col mr-2">
                      <i class="mdi mdi-facebook"></i> Facebook </button>
                    <button class="btn btn-google col">
                      <i class="mdi mdi-google-plus"></i> Google plus </button>
                  </div> */}
                  <p class="sign-up text-center ">Already have an Account? <Link to={'/login'}>Log in</Link></p>
                  <p class="terms">By creating an account you are accepting our<a href="#"> Terms & Conditions</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* <section className="py-0 font-1 mt-6 mb-6">
        <div className="container-fluid">
          <div className="row align-items-center   justify-content-center h-full">
            <div className="col-sm-6 col-md-5 col-lg-4 col-xl-5">
              <h3 className=" mb-5 display-5 text-center"><strong>Sign Up</strong></h3>
              <small className="align-items-center   justify-content-center text-center color-danger">
                  *All fields are required unless marked optional.
                  </small>
              <form class="mt-3 row"  onSubmit={registerSubmit}>
                <div class="form-group">
                  <label for="exampleInputSize3" className="text-left">First Name</label>
                  <input
                  name="firstname" 
                    class="form-control mb-3 input-lg"
                    type="text"
                    placeholder=""
                    onChange={handleInput} value={registerInput.firstname}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.firstname}</label>
                </div>
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Last Name</label>
                  <input
                  name="lastname" 
                    class="form-control mb-3 input-lg"
                    type="text"
                    placeholder=""
                    onChange={handleInput} value={registerInput.lastname}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.lastname}</label>
                </div>
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Email</label>
                  <input
                    class="form-control mb-3 input-lg"
                    name="email" 
                    type="email"
                    placeholder=""
                    onChange={handleInput} value={registerInput.email}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.email}</label>
                </div>
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Address 1</label>
                  <input
                    class="form-control mb-3 input-lg"
                    name="address1" 
                    type="text"
                    placeholder=""
                    onChange={handleInput} value={registerInput.address1}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.address1}</label>
                </div>
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Address 2 (Optional)</label>
                  <input
                    class="form-control mb-3 input-lg"
                    name="address2" 
                    type="text"
                    placeholder=""
                    onChange={handleInput} value={registerInput.address2}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.address2}</label>
                </div>

                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Zip Code</label>
                  <input
                    class="form-control mb-3 input-lg"
                    name="zipcode" 
                    type="text"
                    placeholder=""
                    onChange={handleInput} value={registerInput.zipcode}
                  />{" "}
                  <label className='span_label'>{registerInput.error_list.zipcode}</label>
                </div>

                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Password</label>
                  <input
                    class="form-control mb-3 input-lg"
                    type="password"
                    name="password1" 
                    placeholder=""
                    onChange={handleInput} value={registerInput.password1}
                  />{""}
                  <label className='span_label mb-2'>{registerInput.error_list.password1}</label>
                </div>
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Re-type Password</label>
                  <input
                    class="form-control mb-3 input-lg"
                    type="password"
                    name="password2" 
                    placeholder=""
                    onChange={handleInput} value={registerInput.password2}
                  />{""}
                  <label className='span_label mb-2'>{registerInput.error_list.password2}</label>
                </div>
                <div class="form-group">
                <label for="exampleInputSize3" className="text-left">Tell Us About Your Taste (Optional)</label>
                  <textarea
                    class="form-control mb-3 input-lg"
                    name="bio"
                    rows="8"
                    placeholder=""
                    onChange={handleInput} value={registerInput.bio}
                  ></textarea>
                </div>

                <div className="row align-items-center mt-4">
                  <button className="btn-block btn btn-primary" type="submit">
                    Register
                  </button>
                </div>
              </form>
              <div className="row align-items-center mt-4">
                <div className="col-md-4 text-left">
                  <input
                    class="ios-toggle "
                    id="checkbox1"
                    type="checkbox"
                    name="checkbox1"
                  />
                  <label
                    class="checkbox-label text-center"
                    for="checkbox1"
                    data-off=""
                    data-on=""
                  ></label>
                </div>
                <div className="col-md-8 text-right"> 
                  <small>
                    I agree to the V-Hospitality Terms and Conditions
                  </small>
                 
                </div>
              </div>
            
              <hr className="color-9 mt-2" />
              <div className="fs--4  mb-2 text-center">
                Existing User?
                <Link to="/login"> Log in</Link> <br />
                <Link to="./recoverpass" className="text-center">Forgot your password?</Link>
              </div>
              
              <hr className="color-9" />{" "}
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Register;
