import React from 'react';
import { Link } from 'react-router-dom';
import "../../App.css"
// import ""

function Page404() {
    return(


        <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center text-center error-page bg-primary">
          <div class="row flex-grow">
            <div class="col-lg-7 mx-auto text-white">
              <div class="row align-items-center d-flex flex-row">
                <div class="col-lg-6 text-lg-right pr-lg-4">
                  <h1 class="display-1 mb-0">404</h1>
                </div>
                <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>SORRY!</h2>
                  <h3 class="font-weight-light">The page you’re looking for was not found.</h3>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-12 text-center mt-xl-2">
                <Link class="text-white font-weight-medium" to={'/login'}>Back to Home </Link>
                or go to <a class="text-white font-weight-medium" href='http://vhospitalityapp.v-ng.com/' target="_blank">Booking Site </a> 
                </div>
              
              </div>
              <div class="row mt-5">
                <div class="col-12 mt-xl-2">
                  <p class="text-white font-weight-medium text-center">V-Hospitality &copy; 2023 All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        // <div class="container">
        //                 <div class="row justify-content-center">
        //                     <div class="col-lg-6">
        //                         <div class="text-center mt-4">
        //                         <h1 class="display-1">404</h1>
        //                             <p class="lead">This requested URL was not found on this server.</p>
        //                             <Link to={`/`} className="btn btn-primary">
        //                                 <i class="fas fa-arrow-left me-1 " ></i>
        //                                 Return to Home
        //                             </Link>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
    );
}

export default Page404;

