import React, { useState } from "react";

import swal from "sweetalert";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import "../../App.css";
// import Navbar from "../../../layouts/frontend/Navbar";

function Login() {
  const history = useHistory();
  const [loginInput, setLogin] = useState({
    email: "",
    password1: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    
    const thisClicked = e.currentTarget;
    const data = {
      email: loginInput.email,
      password1: loginInput.password1,
    };
    thisClicked.innerText = "Logging in";

    
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/login", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          // history.push("/v-admin/dashboard");
          if (res.data.role === "admin") {
            swal("Success", res.data.message, "success");
            history.push("/v-admin/dashboard", ...res.data.username);
          } else {
            thisClicked.innerText = "Login";
            swal("Warning", res.data.message, "warning");
            history.push("/403");
          }
        } else if (res.data.status === 401) {
          thisClicked.innerText = "Login";
          swal("Warning", res.data.message, "warning");
        } else {
          thisClicked.innerText = "Login";
          setLogin({ ...loginInput, error_list: res.data.validation_errors });
        }
      });
    });
  };

  return (
    <>
      <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div class="row w-100 m-0">
            <div class="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
              <div class="card col-lg-4 mx-auto">
                <div class="card-body px-5 py-5">
                  <h3 class="card-title text-left mb-3">V-HOSPITALITY ADMIN </h3>
                  <form >
                    <div class="form-group">
                      <label>Email *</label>
                      <input name="email" type="email" class="form-control p_input" 
                      onChange={handleInput}
                      value={loginInput.email}/>
                      <label className="span_label text-warning">
                      {loginInput.error_list.email}
                    </label>
                    </div>

                    <div class="form-group">
                      <label>Password *</label>
                      <input type="password" name="password1" 
                      class="form-control p_input" 
                      onChange={handleInput}
                    value={loginInput.password1}
                      />
                      <label className="span_label text-warning">
                    {loginInput.error_list.password1}
                  </label>
                    </div>
                    <div class="form-group d-flex align-items-center justify-content-between">
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" />{" "}
                          Remember me{" "}
                        </label>
                      </div>
                      <a href="#" class="forgot-pass">
                        Forgot password
                      </a>
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary btn-block enter-btn"
                        onClick={(e) => loginSubmit(e)}
                      >
                        Login
                      </button>
                    </div>
                    {/* <div class="d-flex">
                      <button class="btn btn-facebook mr-2 col">
                        <i class="mdi mdi-facebook"></i> Facebook{" "}
                      </button>
                      <button class="btn btn-google col">
                        <i class="mdi mdi-google-plus"></i> Google plus{" "}
                      </button>
                    </div> */}
                    {/* <p class="sign-up">
                      Don't have an Account?<Link to={'/register'}> Sign Up</Link>
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
