import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="d-sm-flex justify-content-center justify-content-sm-between">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          Copyright © V-Hospitality 2023
        </span>
        {/* <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          {" "}
          Free{" "}
          <a
            href="https://vhospitalityservices.com/contact-us/"
            target="_blank"
          >
            Bootstrap admin templates
          </a>{" "}
          from Bootstrapdash.com
        </span> */}
      </div>
    </footer>
  );
};

export default Footer;
