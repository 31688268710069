import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import ReactLoading from "react-loading";
import { MDBInputGroup, MDBBtn } from "mdb-react-ui-kit";
// import swal from 'sweetalert';

const Category = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [picture, setPicture] = useState({});
  const [errorlist, setError] = useState([]);
  const [allcheckbox, setCheckboxes] = useState([]);
  const [categoryInput, setCategory] = useState({
    slug: "",
    name: "",
    descrip: "",
    status: "",
    meta_title: "",
    meta_keyword: "",
    meta_descrip: "",
    // error_list: [],
  });

  useEffect(() => {
    document.title = "add category";

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const handleInput = (e) => {
    e.persist();
    setCategory({ ...categoryInput, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };

  const handleCheckbox = (e) => {
    e.persist();
    setCheckboxes({...allcheckbox, [e.target.name]:e.target.checked})
  }


  const submitProduct = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', picture.image);
    formData.append('name', categoryInput.name);
    formData.append('slug', categoryInput.slug);
    formData.append('description', categoryInput.descrip);
    formData.append('status', categoryInput.status);
    formData.append('meta_title', categoryInput.meta_title);
    formData.append('meta_keyword', categoryInput.meta_keyword);
    formData.append('meta_descrip', categoryInput.meta_descrip);

    axios.post("/api/store-category", formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        setCategory({...categoryInput, 
            category_id: '',
            slug: '',
            name: '',
            description: '',
            meta_title: '',
            meta_keyword: '',
            meta_descrip: '',
        });
        setError([]);
        history.push("/v-admin/view-category");

      } else if (res.data.status === 400) {
        swal("All Fields are Mandatory","","error");
        setError(res.data.errors);
        console.log(res.data.errors);
      }
    });
  }





  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  }

  return (
    <>


<div class="row ">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Add Property Category</h4>

              <nav aria-label="breadcrumb" className="align-self-start">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/v-admin/view-category" href="#">View Category</Link ></li>
                  <li class="breadcrumb-item active" aria-current="page">Add Category</li>
                </ol>
              </nav>

              <form onSubmit={submitProduct} encType="multipart/form-data">
              <div className="form-group mb-3">
                                    <label>Select Category</label>
                                    {/* <select name="category_id" onChange={handleInput} 
                                    value={categoryInput.id} className="form-control input-lg">
                                        <option>Select Category</option>
                                        <option value="Varnern Residence" >Varnern Residence</option>
                                        <option value="V-Hotels" >V-Hotels</option>
                                        <option value="V-Apartments" >V-Apartments</option>
                                        <option value="V-Homes" >V-Homes</option>
                                    </select> */}
                                    <input type="text" name="category_id" onChange={handleInput} value={categoryInput.id} className="form-control input-lg" />
                                    <small className="text-warning">{errorlist.id}</small>
                                </div>
                                {/* <div className="form-group mb-3">
                                    <label>Slug</label>
                                    <input type="text" name="slug" onChange={handleInput} value={categoryInput.slug} className="form-control input-lg" />
                                    <small className="text-warning">{errorlist.slug}</small>
                                </div> */}
                                <div className="form-group mb-3">
                                    <label>Name {' '}<span className="text-muted">(Could be the same as the category name)</span></label>
                                    <input type="text" name="name" onChange={handleInput} value={categoryInput.name} className="form-control input-lg" />
                                    <small className="text-warning">{errorlist.name}</small>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Property Description {' '}<span className="text-muted">(A sentence description about this property category)</span></label>
                                    <textarea rows={6} name="descrip"  onChange={handleInput} 
                                    value={categoryInput.descrip} className="form-control input-lg"></textarea>
                                    <small className="text-warning">{errorlist.description}</small>
                                </div>


                                {/* SEO TAGS */}

                                {/* <div className="form-group mb-3">
                                    <label>Meta Title {' '}<span className="text-muted">(tags you want to associate with your property)</span></label>
                                    <input type="text" name="meta_title" onChange={handleInput} value={categoryInput.meta_title} className="form-control input-lg" />
                                    <small className="text-warning">{errorlist.meta_title}</small>
                                </div> */}
                                <div className="form-group mb-3">
                                    <label>Meta Keyword {' '}<span className="text-muted">(keywords you want to associate with your property)</span></label>
                                    <textarea name="meta_keyword" onChange={handleInput} value={categoryInput.meta_keyword}  className="form-control input-lg"></textarea>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Meta Description{' '}<span className="text-muted">(A short description you want to associate with your property)</span></label>
                                    <textarea rows={6} name="meta_descrip" onChange={handleInput} value={categoryInput.meta_descrip}  className="form-control input-lg"></textarea>
                                </div>

                                <div className="col-md-4 mb-3">
                                        <div className="zinput zcheckbox">
                                            <input id="status" type="checkbox" name="status" onChange={handleInput} value={categoryInput.status} />
                                            <label for="status" className="text-muted">Status (checked=Hidden)</label>
                                        </div>
                                    </div>


                            {/*  */}

                            <div className="row">
                                <div className="col-12">
                                <hr className='mt-4' />
                                    <h3>Upload Images</h3>
                                    <small className='text-success mb-4'>Images size should not exceed 2mb</small>
                                    {/* Image Upload */}
                                    


                                        <MDBInputGroup
                                            className='mb-3'
                                            textBefore='Upload Banner Image'
                                            textTag='label'
                                            textProps={{ htmlFor: 'inputGroupFile01' }}>
                                            <input 
                                            className='form-control' 
                                            type='file' 
                                            id='inputGroupFile01' 
                                            name="image" 
                                            onChange={handleImage} /> 
                                            <small className="text-warning">{errorlist.image}</small>
                                        </MDBInputGroup>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-outline-warning btn-lg btn-fw px-4 mt-2">
                                Submit
                            </button>

              </form>



            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Category;
