import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import swal from "sweetalert";
import { MDBInputGroup, MDBBtn } from "mdb-react-ui-kit";

// const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;

function EditProduct(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorlist, setError] = useState([]);
  const [productInput, setProduct] = useState({
    category_id: "",
    slug: "",
    name: "",
    description: "",

    meta_title: "",
    meta_keyword: "",
    meta_descrip: "",

    beds: "",
    baths: "",
    telephone: "",
    vendor_email: "",

    checkin: "",
    checkout: "",

    long: "",
    lat: "",

    swimmingpool: "",
    freeparking: "",
    roomservice: "",
    restuarant: "",
    nosmoke: "",
    frdesk: "",
    laundry: "",

    selling_price: "",
    original_price: "",
    qty: "",
    brand: "",
  });
  const [allcheckbox, setCheckboxes] = useState([]);
  const handleCheckbox = (e) => {
    e.persist();
    setCheckboxes({ ...allcheckbox, [e.target.name]: e.target.checked });
  };

  const [categorylist, setCategorylist] = useState([]);
  const [productlist, setProductlist] = useState([]);
  const [picture, setPicture] = useState({});

  const updateProduct = (e) => {
    e.preventDefault();

    const product_id = props.match.params.id;

    const formData = new FormData();
    formData.append("image", picture.image);
    formData.append("category_id", productInput.category_id);
    formData.append("slug", productInput.slug);
    formData.append("name", productInput.name);
    formData.append("description", productInput.description);

    formData.append("meta_title", productInput.meta_title);
    formData.append("meta_keyword", productInput.meta_keyword);
    formData.append("meta_descrip", productInput.meta_descrip);

    formData.append("selling_price", productInput.selling_price);
    formData.append("original_price", productInput.original_price);
    formData.append("qty", productInput.qty);
    formData.append("brand", productInput.brand);
    formData.append("location", productInput.location);

    formData.append("beds", productInput.beds);
    formData.append("baths", productInput.baths);
    formData.append("telephone", productInput.telephone);
    formData.append("vendor_email", productInput.vendor_email);

    formData.append("checkin", productInput.checkin);
    formData.append("checkout", productInput.checkout);

    formData.append("long", productInput.long);
    formData.append("lat", productInput.lat);

    formData.append("swimmingpool", allcheckbox.swimmingpool ? "1" : "0");
    formData.append("freeparking", allcheckbox.freeparking ? "1" : "0");
    formData.append("roomservice", allcheckbox.roomservice ? "1" : "0");
    formData.append("restuarant", allcheckbox.restuarant ? "1" : "0");
    formData.append("nosmoke", allcheckbox.nosmoke ? "1" : "0");
    formData.append("frdesk", allcheckbox.frdesk ? "1" : "0");
    formData.append("laundry", allcheckbox.laundry ? "1" : "0");

    formData.append("featured", allcheckbox.featured ? "1" : "0");
    formData.append("popular", allcheckbox.popular ? "1" : "0");
    formData.append("status", allcheckbox.status ? "1" : "0");

    axios.post(`/api/update-product/${product_id}`, formData).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        console.log(allcheckbox);
        setError([]);
        history.push("/v-admin/view-houseproduct");
      } else if (res.data.status === 422) {
        swal("All Fields are Mandatory", "", "error");
        setError(res.data.errors);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/admin/view-product");
      }
    });
  };
  const handleInput = (e) => {
    e.persist();
    setProduct({ ...productInput, [e.target.name]: e.target.value });
  };
  const handleImage = (e) => {
    e.persist();
    setPicture({ image: e.target.files[0] });
  };


  useEffect(() => {
    document.title = "edit product";

    const product_id = props.match.params.id;
    console.log(product_id);
    axios.get(`/api/edit-product/${product_id}`).then((res) => {
      if (res.data.status === 200) {
        // console.log(res.data.product);
        setProduct(res.data.product);
        setCheckboxes(res.data.product);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/admin/view-houseproduct");
      }
      setLoading(false);
    });

    axios.get("/api/all-category").then((res) => {
      if (res.data.status === 200) {
        setCategorylist(res.data.category);
      }
    });

    axios.get("/api/view-house").then((res) => {
      if (res.data.status === 200) {
        setProductlist(res.data.houses);
      }
    });
  }, [props.match.params.id, history]);

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid px-4">
        <div className="card mt-4">
          <div className="card-header">
            <h4>
              Edit Property
            </h4>
          <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active" aria-current="page">Edit Property</li>
                  <li class="breadcrumb-item"><Link to="/v-admin/view-houseproduct" href="#">View Property</Link ></li>
                </ol>
              </nav>
          </div>


          <div className="card-body">
            <form onSubmit={updateProduct} encType="multipart/form-data">
              <div className="row">
                <div className="form-group mb-3 col-12">
                  <label>Select Category</label>
                  <select
                    name="category_id"
                    onChange={handleInput}
                    value={productInput.category_id}
                    className="form-control input-lg"
                  >
                    <option>Select Category</option>
                    {categorylist.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <small className="text-warning">{errorlist.category_id}</small>
                </div>
                {/* <div className="form-group mb-3 col-12">
                  <label>Slug</label>
                  <input
                    type="text"
                    name="slug"
                    onChange={handleInput}
                    value={productInput.slug}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{errorlist.slug}</small>
                </div> */}
                <div className="form-group mb-3 col-12">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInput}
                    value={productInput.name}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{errorlist.name}</small>
                </div>
                <div className="form-group mb-3 col-12">
                  <label>Description</label>
                  <textarea
                    name="description"
                    onChange={handleInput}
                    value={productInput.description}
                    className="form-control input-lg"
                    rows="6"
                  ></textarea>
                </div>
              </div>

              <div className="row">
                {/* SEO */}
                <div className="form-group mb-3 col-12">
                  <label>Meta Title</label>
                  <input
                    type="text"
                    name="meta_title"
                    onChange={handleInput}
                    value={productInput.meta_title}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{errorlist.meta_title}</small>
                </div>
                <div className="form-group mb-3 col-12">
                  <label>Meta Keyword</label>
                  <textarea
                    name="meta_keyword"
                    onChange={handleInput}
                    value={productInput.meta_keyword}
                    className="form-control input-lg"
                  ></textarea>
                </div>
                <div className="form-group mb-3 col-12">
                  <label>Meta Description</label>
                  <textarea
                    name="meta_descrip"
                    onChange={handleInput}
                    value={productInput.meta_descrip}
                    className="form-control input-lg"
                  ></textarea>
                </div>
              </div>

              {/* DETAILS */}
              <div className="row mb-5">
                <div className="col-md-4 form-group mb-3">
                  <label>Selling Price</label>
                  <input
                    type="text"
                    name="selling_price"
                    onChange={handleInput}
                    value={productInput.selling_price}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">
                    {errorlist.selling_price}
                  </small>
                </div>
                <div className="col-md-4 form-group mb-3">
                  <label>Original Price</label>
                  <input
                    type="text"
                    name="original_price"
                    onChange={handleInput}
                    value={productInput.original_price}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">
                    {errorlist.original_price}
                  </small>
                </div>
                <div className="col-md-4 form-group mb-3">
                  <label>Quantity</label>
                  <input
                    type="text"
                    name="qty"
                    onChange={handleInput}
                    value={productInput.qty}
                    className="form-control input-lg"
                  />
                  <small className="text-warning">{errorlist.qty}</small>
                </div>

                <div className="col-md-4 form-group mb-3">
                  <label>Brand</label>
                  <select
                    name="category_id"
                    onChange={handleInput}
                    value={productInput.brand}
                    className="form-control input-lg"
                  >
                    <option>Select Brand</option>
                    {productlist.map((item) => {
                      return (
                        <option value={item.brand} key={item.id}>
                          {item.brand}
                        </option>
                      );
                    })}
                  </select>
                  <small className="text-warning">{errorlist.category_id}</small>
                </div>

                <div className="col-md-4 form-group mb-3">
                  <label>Location</label>
                  {/* <input
                    type="text"
                    name="location"
                    onChange={handleInput}
                    value={productInput.location}
                    className="form-control input-lg"
                  /> */}
                  <select
                      name="location"
                      onChange={handleInput}
                      value={productInput.location}
                      className="form-control input-lg">
                      <option>Select Location</option>
                      <option value="Asokoro" >Asokoro</option>
                      <option value="Gudu" >Gudu</option>
                      <option value="Gwarinpa" >Gwarinpa</option>
                      <option value="Idu" >Idu</option>
                      <option value="Jabi" >Jabi</option>
                      <option value="Jahi" >Jahi</option>
                      <option value="Lugbe" >Lugbe</option>
                      <option value="Life-camp" >Life-camp</option>
                      <option value="Katampe" >Katampe</option>
                      <option value="Maitama" >Maitama</option>
                    </select>
                  <small className="text-warning">{errorlist.location}</small>
                </div>

                <div className="col-md-4 form-group mb-3">
                  <label>Telephone</label>
                  <input
                    type="text"
                    name="telephone"
                    onChange={handleInput}
                    value={productInput.telephone}
                    className="form-control input-lg"
                  />
                  {/* <small className="text-warning">{errorlist.location}</small> */}
                </div>

                <div className="col-md-4 form-group mb-3">
                  <label>Vendor's Email Address</label>
                  <input
                    type="text"
                    name="vendor_email"
                    onChange={handleInput}
                    value={productInput.vendor_email}
                    className="form-control input-lg"
                  />
                  {/* <small className="text-warning">{errorlist.location}</small> */}
                </div>
                <div className="col-md-4 form-group mb-3">
                    <label>Property Longitude on Map</label>
                    <input
                      type="text"
                      name="long"
                      onChange={handleInput}
                      value={productInput.long}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.long}
                    </small>
                  </div>

                  <div className="col-md-4 form-group mb-3">
                    <label>Property Latitude on Map </label>
                    <input
                      type="text"
                      name="lat"
                      onChange={handleInput}
                      value={productInput.lat}
                      className="form-control input-lg"
                    />
                    <small className="text-warning">
                      {errorlist.lat}
                    </small>
                  </div>



                

                {/* <div className="row">
                  <hr className="mt-4" />
                  <h1 className="fs-6 p-4 mt-6 mb-2 text-white color-vhospitalilty-b ">
                    UPLOAD IMAGES
                  </h1>
                  <small className="text-success mb-4">
                    Images size should not exceed 2mb
                  </small>

                  <div className="col-md-8 form-group mb-3">
                    <label>Image</label>
                    <input
                      type="file"
                      name="image"
                      onChange={handleImage}
                      className="form-control input-lg"
                    />
                    <img
                      src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${productInput.image}`}
                      width="50px"
                      alt={productInput.name}
                    />
                    <small className="text-warning">{errorlist.image}</small>
                  </div>
                </div> */}
              </div>

              
              <div className="row">
                  <strong class="card-title text-warning">
                    FACILITIES AVAILABLE IN PROPERTY{" "}
                    <span className="text-muted">
                      (Please select where applicable)
                    </span>
                  </strong>
                  <br />
                </div>
                <br/>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        type="checkbox"
                        id="swimmingpool"
                        name="swimmingpool"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.swimmingpool === 1 ? true : false
                        }
                        // value={productInput.swimmingpool}
                      />
                      <label for="swimmingpool" className="text-muted">SWIMMING POOL | Status 0=shown/1=hidden</label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="freeparking"
                        type="checkbox"
                        name="freeparking"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.freeparking === 1 ? true : false
                        }
                        // value={productInput.freeparking}
                      />
                      <label for="freeparking" className="text-muted">FREE PARKING</label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="roomservice"
                        type="checkbox"
                        name="roomservice"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.roomservice === 1 ? true : false
                        }
                        // value={productInput.roomservice}
                      />
                      <label for="roomservice" className="text-muted">ROOM SERVICE </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="restuarant"
                        type="checkbox"
                        name="restuarant"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.restuarant === 1 ? true : false
                        }
                        // value={productInput.restuarant}
                      />
                      <label for="restuarant" className="text-muted">ON-SITE RESTAURANT </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="nosmoke"
                        type="checkbox"
                        name="nosmoke"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.nosmoke === 1 ? true : false
                        }
                        // value={productInput.nosmoke}
                      />
                      <label for="nosmoke" className="text-muted">NON-SMOKING ROOMS </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="frdesk"
                        type="checkbox"
                        name="frdesk"
                        onChange={handleCheckbox}
                        defaultChecked={allcheckbox.frdesk === 1 ? true : false}
                        // value={productInput.frdesk}
                      />
                      <label for="frdesk" className="text-muted">24 HOUR FRONT DESK </label>
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="zinput zcheckbox">
                      <input
                        id="laundry"
                        type="checkbox"
                        name="laundry"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.laundry === 1 ? true : false
                        }
                        // value={productInput.laundry}
                      />
                      <label for="laundry" className="text-muted">LAUNDRY SERVICES </label>
                    </div>
                  </div>



                </div>

                <div className="row mb-5">
                    <div className="col-md-6 form-group mb-3">
                      <label>No. of BEDs</label>
                      <input
                        type="text"
                        name="beds"
                        onChange={handleInput}
                        value={productInput.beds}
                        className="form-control input-lg"
                      />
                      {/* <small className="text-warning">{errorlist.location}</small> */}
                    </div>

                    <div className="col-md-6 form-group mb-3">
                      <label>No. of BATHs</label>
                      <input
                        type="text"
                        name="baths"
                        onChange={handleInput}
                        value={productInput.baths}
                        className="form-control input-lg"
                      />
                      {/* <small className="text-warning">{errorlist.location}</small> */}
                    </div>

                    <div className="col-md-6">
                    <label>
                        Check in
                      </label>
                    <input
                        name="checkin"
                        onChange={handleInput}
                        className="form-control input-lg"
                        type="date"
                        value={productInput.checkin}
                      />{" "}
                      <small className="text-warning">{errorlist.checkin}</small>
                    </div>
                    <div className="col-md-6">
                    <label>
                        Check out
                      </label>
                    <input
                        name="checkout"
                        onChange={handleInput}
                        className="form-control input-lg"
                        type="date"
                        value={productInput.checkout}
                      />{" "}
                      <small className="text-warning">{errorlist.checkout}</small>
                    </div>
                  </div>





              
                  <div className="row">
                  <strong class="card-title text-warning">
                    VIEWERS STATUS{" "}
                    <span className="text-muted">
                      (Please select all checkboxes)
                    </span>
                  </strong>
                  <br />
             
                </div>
                <div className="row">

                  <div className="col-md-4 mb-3">
                        <div className="zinput zcheckbox">
                          <input
                            type="checkbox"
                            id="featured"
                            name="featured"
                            onChange={handleCheckbox}
                            defaultChecked={
                              allcheckbox.featured === 1 ? true : false
                            }
                            
                          />
                          <label for="featured" className="text-muted">Featured (checked=shown)</label>
                        </div>
                      </div>

                      <div className="col-md-4 mb-3">
                        <div className="zinput zcheckbox">
                          <input
                            id="popular"
                            type="checkbox"
                            name="popular"
                            onChange={handleCheckbox}
                            defaultChecked={
                              allcheckbox.popular === 1 ? true : false
                            }
                            
                          />
                          <label for="popular" className="text-muted">Popular (checked=shown)</label>
                        </div>
                      </div>

                      <div className="col-md-4 mb-3">
                        <div className="zinput zcheckbox">
                          <input
                             id="status"
                             type="checkbox"
                             name="status"
                             onChange={handleCheckbox}
                             defaultChecked={allcheckbox.status === 1 ? true : false}
                            
                          />
                          <label for="status" className="text-muted">Status (checked=Hidden)</label>
                        </div>
                      </div>


                      <div className="row">
                  <div className="col-12">
                    <hr className="mt-4" />
                    <strong class="card-title text-warning">
                          UPLOAD PHOTOS{" "}
                          <span className="text-muted">
                            (Images size should not exceed 2mb)
                          </span>
                        </strong>
                    {/* Image Upload */}

                    <MDBInputGroup
                      className="mb-3 mt-4"
                      textBefore="Upload Property Photo 1"
                      textTag="label"
                      textProps={{ htmlFor: "inputGroupFile01" }}
                    >
                      <input
                        className="form-control  file-upload-info"
                        type="file"
                        id="inputGroupFile01"
                        name="image"
                        accept="image/*"
                        // multiple="multiple"
                        onChange={handleImage}
                      />
                      <img
                        src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${productInput.image}`}
                        width="50px"
                        alt={productInput.name}
                      />
                      <small className="text-warning">{errorlist.image}</small>
                    </MDBInputGroup>
                  </div>
                </div>





                  {/* <div className="col-md-4 mb-3">
                    <div class="zinput zcheckbox">
                      <input
                        type="checkbox"
                        id="featured"
                        name="featured"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.featured === 1 ? true : false
                        }
                      />
                      <label for="featured">Featured (checked=shown)</label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div class="zinput zcheckbox">
                      <input
                        id="popular"
                        type="checkbox"
                        name="popular"
                        onChange={handleCheckbox}
                        defaultChecked={
                          allcheckbox.popular === 1 ? true : false
                        }
                      />
                      <label for="popular">Popular (checked=shown)</label>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div class="zinput zcheckbox input-lg">
                      <input
                        id="status"
                        type="checkbox"
                        name="status"
                        onChange={handleCheckbox}
                        defaultChecked={allcheckbox.status === 1 ? true : false}
                      />
                      <label for="status">Status (checked=Hidden)</label>
                    </div>
                  </div> */}

                </div>

              <button type="submit" className="btn btn-outline-warning btn-lg btn-fw px-4 mt-2">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProduct;
