import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "./v-admin-components/auth/Login";
import Register from "./v-admin-components/auth/Register";
import Page403 from "./v-admin-components/errors/403";
import Page404 from "./v-admin-components/errors/404";
import axios from "axios";
import AdminPrivateRoute from "./AdminPrivateRoute";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import ScriptTag from 'react-script-tag'
import {Helmet} from 'react-helmet'


import "bootstrap/dist/js/bootstrap.bundle.js";

import "../src/assets/css/style.css";
import "../src/App.css";

import ImportScript from './v-admin-components/JavaScriptsHooks'

// import '../src/assets/js/off-canvas.js'


// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';

{
  /* <script src="../src/assets/vendors/js/vendor.bundle.base.js"></script>
<!-- endinject -->
<!-- Plugin js for this page -->
<script src="../src/assets/vendors/chart.js/Chart.min.js"></script>
<script src="../src/assets/vendors/progressbar.js/progressbar.min.js"></script>
<script src="../src/assets/vendors/jvectormap/jquery-jvectormap.min.js"></script>
<script src="../src/assets/vendors/jvectormap/jquery-jvectormap-world-mill-en.js"></script>
<script src="../src/assets/vendors/owl-carousel-2/owl.carousel.min.js"></script>
<!-- End plugin js for this page -->
<!-- inject:js -->
<script src="../src/assets/js/off-canvas.js"></script>
<script src="../src/assets/js/hoverable-collapse.js"></script>
<script src="../src/assets/js/misc.js"></script>
<script src="../src/assets/js/settings.js"></script>
<script src="../src/assets/js/todolist.js"></script>
<!-- endinject -->
<!-- Custom js for this page -->
<script src="../src/assets/js/dashboard.js"></script> */
}

const Demo = props => {
  ImportScript("../src/assets/js/off-canvas.js");
}


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

axios.defaults.baseURL = `${process.env.REACT_APP_LOCALHOST_APP_URL}/`;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = true;

// for logging out
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {




  return (
    
    <div className="App">
        <Helmet>
              <script src="../src/assets/js/off-canvas.js" type="text/javascript" />
            </Helmet>
      <Router>
        <Switch>
        <Route exact path="/" component={Login} />
          <AdminPrivateRoute path="/v-admin" name="v-admin" />

          <Route exact path="/403" component={Page403} />

          <Route exact path="/404" component={Page404} />
          {/* <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} /> */}

          {/* <Route path="/login">
            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Login />}
          </Route> */}

          <Route path="/login">
            {localStorage.getItem("auth_token") ? (
              <Redirect to="/" />
            ) : (
              <Login />
            )}
          </Route>

          <Route path="/register">
            {localStorage.getItem("auth_token") ? (
              <Redirect to="/" />
            ) : (
              <Register />
            )}
          </Route>
        </Switch>
      </Router>

      {AddLibrary("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js")}
      {AddLibrary("https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.3/Chart.min.js")}
      {AddLibrary("https://unpkg.com/progressbar@1.1.0/index.js")}
      {AddLibrary("https://unpkg.com/jvectormap@2.0.4/jquery-jvectormap.min.js")}
      {AddLibrary("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js")}
      {/* {ImportScript("../src/assets/js/off-canvas.js")} */}
      {/* <ScriptTag type="text/javascript" src="/src/assets/js/off-canvas.js" /> */}
  
    </div>
  );
}

// app.get('/cors', (req, res) => {
//   res.set('Access-Control-Allow-Origin', 'http://localhost:3000');
//   res.send({ "msg": "This has CORS enabled 🎈" })
//   })

export default App;
