import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactLoading from "react-loading";
import default_img from "../../assets/img/emmy.png";

import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

function CashOnArrival() {
  const [allCoaTran, setallCashOnArrivalTran] = useState([]);
  const [loading, setLoading] = useState(true);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["firstname", "lastname", "payment_mode"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  useEffect(() => {
    axios.get(`/api/get-coa-order`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.allcoa);
        setallCashOnArrivalTran(res.data.allcoa);
        setLoading(false);
      }
    });
  }, []);

  const data = Object.values(allCoaTran);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  var display_TransactionRecipts = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } else {
    display_TransactionRecipts = allCoaTran.map((item, idx) => {
      return (
        <>
          <tr key={idx}>
            <td>{item.id}</td>
            <td>
              {" "}
              {item.firstname} {item.lastname}{" "}
            </td>
            <td>
              {" "}
              {item.payment_mode === "paystack" ? (
                <div class="badge badge-outline-success">Paystack</div>
              ) : (
                <div class="badge badge-outline-warning">Pay on Arrival</div>
              )}{" "}
            </td>

            <td>
              {" "}
              {item.payment_mode === "paystack" ? (
                <div class="badge badge-outline-success">
                  {item.paystack.redirecturl}
                </div>
              ) : (
                <div class="badge badge-outline-warning">
                  {item.tracking_no}
                </div>
              )}{" "}
            </td>

            <td>{item.created_at}</td>

            <td>
              {" "}
              {item.status === 0 ? (
                <div class="badge badge-outline-success">Success</div>
              ) : (
                <div class="badge badge-outline-danger">Failed</div>
              )}{" "}
            </td>

            <td> {item.price} </td>

            <td>
              {" "}
              {item.status === 0 ? (
                <div class="badge badge-outline-success">Success</div>
              ) : (
                <div class="badge badge-outline-danger">Failed</div>
              )}{" "}
            </td>

            <td>
              {" "}
              <Link
                to={`view-tran-details/${item.user_id}`}
                class="btn btn-outline-secondary btn-icon-text"
              >
                {" "}
                VIEW <i class="mdi mdi-eye btn-icon-append"></i>
              </Link>
            </td>
          </tr>
        </>
      );
    });
  }

  return (
    <>
      <div class="row mb-3">
      <form class="nav-link mt-2 mt-md-0 d-none d-lg-flex search w-100">
        <input
          type="search"
          className="form-control form-control-lg input_border"
          placeholder="Search by Customer Firstname or Lastname, or Payment mode..."
          aria-label="Search"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
      </form>
      </div>

      

      <div class="row ">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Payments On Arrival - Transaction Details</h4>
              <div class="table-responsive">
                <table class="table  table-dark table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Client Name </th>
                      <th>Payment Mode</th>
                      <th>Payment ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Add here */}
                    {display_TransactionRecipts}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashOnArrival;
