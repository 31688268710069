import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
// import img from '../../assets_old/admin/assets/img/logo1.png'
import { useHistory, useLocation } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import swal from "sweetalert";

import { MDBBadge, MDBBtn } from "mdb-react-ui-kit";

function Dashboard() {
  // const { process.env.REACT_APP_LOCALHOST_APP_URL } = process.env;
  const history = useHistory();
  const [allHomes, setAllHomes] = useState([]);
  const [availableHomes, setAvailableHomes] = useState([]);
  const [bookedHomes, setBookedHomes] = useState([]);
  const [allTranOder, setallTranOder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registerInput, setUser] = useState([]);

  const [categorylist, setCategorylist] = useState([]);

  const [productV, setVanernProduct] = useState([]);
  const [categoryV, setVanernCategory] = useState([]);

  const [productVH, setVhotelProduct] = useState([]);
  const [categoryVH, setVhotelCategory] = useState([]);

  const [productVA, setVapartmentProduct] = useState([]);
  const [categoryVA, setVapartmentCategory] = useState([]);

  const [productVHO, setVhomesProduct] = useState([]);
  const [categoryVHO, setVhomesCategory] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "Login to goto Cart Page", "error");
    }

    let isMounted = true;

    axios.get(`/api/getUserDetail`).then((res) => {
      if (res.data.status === 200) {
        setUser(res.data.user);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/account");
      }
      // setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [history]);

  useEffect(() => {
    document.title = "Dashboard";
    let isMounted = true;

    axios.get(`/api/get-available-homes`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.avaiproduct);
        setAvailableHomes(res.data.avaiproduct);
        setLoading(false);
      }
    });

    axios.get(`/api/get-booked-homes`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.bookedproduct);
        setBookedHomes(res.data.bookedproduct);
        setLoading(false);
      }
    });

    axios.get(`/api/get-homes-count`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.allhomes);
        setAllHomes(res.data.allhomes);
        setLoading(false);
      }
    });

    axios.get(`/api/get-order-details`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.allorder);
        setallTranOder(res.data.allorder);
        setLoading(false);
      }
    });

    axios.get(`/api/get-vanern`).then((res) => {
      if (res.data.status === 200) {
        setVanernProduct(res.data.product_data.product);
        setVanernCategory(res.data.product_data.category);
        setLoading(false);
      } else if (res.data.status === 400) {
        swal("Warning", res.data.message, "");
      } else if (res.data.status === 404) {
        swal("Warning", res.data.message, "error");
        history.push("/v-admin");
      }
    });

    axios.get(`/api/get-vhotels`).then((res) => {
      if (res.data.status === 200) {
        setVhotelProduct(res.data.product_data.product);
        setVhotelCategory(res.data.product_data.category);
        setLoading(false);
      } else if (res.data.status === 400) {
        swal("Warning", res.data.message, "");
      } else if (res.data.status === 404) {
        swal("Warning", res.data.message, "error");
        history.push("/v-admin");
      }
    });

    axios.get(`/api/get-vapartment`).then((res) => {
      if (res.data.status === 200) {
        setVapartmentProduct(res.data.product_data.product);
        setVapartmentCategory(res.data.product_data.category);
        setLoading(false);
      } else if (res.data.status === 400) {
        swal("Warning", res.data.message, "");
      } else if (res.data.status === 404) {
        swal("Warning", res.data.message, "error");
        history.push("/v-admin");
      }
    });

    axios.get(`/api/get-vhomes`).then((res) => {
      if (res.data.status === 200) {
        setVhomesProduct(res.data.product_data.product);
        setVhomesCategory(res.data.product_data.category);
        setLoading(false);
      } else if (res.data.status === 400) {
        swal("Warning", res.data.message, "");
      } else if (res.data.status === 404) {
        swal("Warning", res.data.message, "error");
        history.push("/v-admin");
      }
    });
  }, []);

  // useEffect(() => {
  //   let isMounted = true;

  //   const product_slug = props.match.params.slug;
  //   axios.get(`/api/fetchproducts/${product_slug}`).then((res) => {
  //     if (isMounted) {
  //       if (res.data.status === 200) {
  //         setProduct(res.data.product_data.product);
  //         setCategory(res.data.product_data.category);
  //         setLoading(false);
  //       } else if (res.data.status === 400) {
  //         swal("Warning", res.data.message, "");
  //       } else if (res.data.status === 404) {
  //         swal("Warning", res.data.message, "error");
  //         history("/collections");
  //       }
  //     }
  //   });

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [props.match.params.slug, history]);

  var display_TransactionRecipts = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        {/* <img src={img}  width="120px" /> */}
        <ReactLoading type={"spin"} color={"white"} height={80} width={80} />
      </div>
    );
  } else {
    display_TransactionRecipts = allTranOder.map((item, idx) => {
      // console.log(item.room.price);
      return (
        <tr key={idx}>
          <th scope="row">{item.id}</th>
          <td>
            {item.firstname} {item.lastname}
          </td>
          <td>{item.property_name}</td>
          <td>
            {item.status === 0 ? (
              <MDBBadge pill color="warning">
                Success
              </MDBBadge>
            ) : (
              <MDBBadge color="danger" pill>
                Failed
              </MDBBadge>
            )}
          </td>
          <td>{item.price}</td>
          <td>
            {item.payment_mode === "paystack" ? (
              <MDBBadge pill color="warning">
                Paystack
              </MDBBadge>
            ) : (
              <MDBBadge color="warning" pill>
                Pay on Delivery
              </MDBBadge>
            )}
          </td>

          {/* {item.payment_mode === "paystack" ? (
            <td>{item.paystack.redirecturl}</td>
          ) : (
            <td> {item.tracking_no}</td>
          )} */}

          <td>
            <Link to={`view-tran-details/${item.user_id}`}>
              <MDBBtn rounded color="info">
                View
              </MDBBtn>
            </Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <div class="row">
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{allHomes.length}</h3>
                    <p class="text-success ml-2 mb-0 font-weight-medium">
                      Properties
                    </p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon icon-box-success ">
                    <span class="mdi mdi-home icon-item"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">Total Property</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{availableHomes.length}</h3>
                    <p class="text-success ml-2 mb-0 font-weight-medium">
                      Properties
                    </p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon icon-box-success">
                    <span class="mdi mdi mdi-home icon-item"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">
                No of Available Property
              </h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">{bookedHomes.length}</h3>
                    <p class="text-info ml-2 mb-0 font-weight-medium">
                      Properties
                    </p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon icon-box-success">
                    <span class="mdi mdi-home icon-item"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">
                No of Property Booked
              </h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <div class="d-flex align-items-center align-self-start">
                    <h3 class="mb-0">10</h3>
                    <p class="text-success ml-2 mb-0 font-weight-medium">
                      Users
                    </p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="icon icon-box-success ">
                    <span class="mdi mdi-human-male-female icon-item"></span>
                  </div>
                </div>
              </div>
              <h6 class="text-muted font-weight-normal">
                Registered Guest/Users
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-8">Transaction History</h4>
              <canvas
                id="transaction-history"
                class="transaction-chart"
              ></canvas>
              <p class="text-muted mb-0">Cash on Arrival Transaction</p>
              <div class="progress progress-md portfolio-progress">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: "70%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="text-muted mb-0 mt-4">Paystack Transaction</p>
              <div class="progress progress-md portfolio-progress ">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style={{ width: "50%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                <div class="text-md-center text-xl-left">
                  <h6 class="mb-1">Total Payments on Arrival</h6>
                  <p class="text-muted mb-0">07 Jan 2023, 09:12AM</p>
                </div>
                <div class="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                  <h6 class="font-weight-bold mb-0">
                    <span className="menu-icon">
                      <i className="mdi mdi-currency-ngn"></i>
                    </span>
                    250,000,200
                  </h6>
                </div>
              </div>
              <div class="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                <div class="text-md-center text-xl-left">
                  <h6 class="mb-1">Total Payment via Paystack</h6>
                  <p class="text-muted mb-0">07 Jan 2023, 09:12AM</p>
                </div>
                <div class="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                  <h6 class="font-weight-bold mb-0">
                    <span className="menu-icon">
                      <i className="mdi mdi-currency-ngn"></i>
                    </span>
                    655,590
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-row justify-content-between">
                <h4 class="card-title mb-1">Quick Overview</h4>
                <p class="text-muted mb-1">Value</p>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="preview-list">
                    <Link
                      className="nav-link"
                      to={`/v-admin/open-category/${categoryV.id}`}
                    >
                      <div class="preview-item border-bottom">
                        <div class="preview-thumbnail">
                          <div class="preview-icon bg-warning">
                            <i class="mdi mdi-home"></i>
                          </div>
                        </div>
                        <div class="preview-item-content d-sm-flex flex-grow">
                          <div class="flex-grow">
                            <h6 class="preview-subject">Total Properties</h6>
                            <p class="text-muted mb-0">{categoryV.name}</p>
                          </div>
                          <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p class="text-muted">{productV.length}</p>
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      className="nav-link"
                      to={`/v-admin/open-category/${categoryVH.id}`}
                    >
                      <div class="preview-item border-bottom">
                        <div class="preview-thumbnail">
                          <div class="preview-icon bg-warning">
                            <i class="mdi mdi-home"></i>
                          </div>
                        </div>
                        <div class="preview-item-content d-sm-flex flex-grow">
                          <div class="flex-grow">
                            <h6 class="preview-subject"> Total Properties </h6>
                            <p class="text-muted mb-0"> {categoryVH.name}</p>
                          </div>
                          <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p class="text-muted">{productVH.length}</p>
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      className="nav-link"
                      to={`/v-admin/open-category/${categoryVA.id}`}
                    >
                      <div class="preview-item border-bottom">
                        <div class="preview-thumbnail">
                          <div class="preview-icon bg-warning">
                            <i class="mdi mdi-home"></i>
                          </div>
                        </div>
                        <div class="preview-item-content d-sm-flex flex-grow">
                          <div class="flex-grow">
                            <h6 class="preview-subject"> Total Properties </h6>
                            <p class="text-muted mb-0">{categoryVA.name}</p>
                          </div>
                          <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p class="text-muted">{productVA.length}</p>
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      className="nav-link"
                      to={`/v-admin/open-category/${categoryVHO.id}`}
                    >
                      <div class="preview-item border-bottom">
                        <div class="preview-thumbnail">
                          <div class="preview-icon bg-warning">
                            <i class="mdi mdi-home"></i>
                          </div>
                        </div>
                        <div class="preview-item-content d-sm-flex flex-grow">
                          <div class="flex-grow">
                            <h6 class="preview-subject">Total Properties </h6>
                            <p class="text-muted mb-0">{categoryVHO.name}</p>
                          </div>
                          <div class="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p class="text-muted">{productVHO.length}</p>
                            {/* <p class="text-muted mb-0">35 tasks, 7 issues </p> */}
                          </div>
                        </div>
                      </div>
                    </Link>

                    <div class="template-demo">
                      <Link
                        to={`/v-admin/avail-props`}
                        class="btn btn-inverse-warning  btn-icon-text btn-lg"
                      >
                        <i class="mdi mdi-file-check btn-icon-prepend"></i>{" "}
                        Available Properties
                      </Link>

                      <Link
                        to={`/v-admin/booked-props`}
                        class="btn btn-inverse-warning  btn-icon-text btn-lg"
                      >
                        Booked Properties{" "}
                        <i class="mdi mdi-file-check btn-icon-append"></i>
                      </Link>

                      <Link
                        to={`/v-admin/view-category`}
                        class="btn btn-inverse-warning  btn-icon-text btn-lg"
                      >
                        <i class="mdi mdi-alert btn-icon-prepend"></i> Property
                        Category
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
